import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import '../../index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faBan, faSave } from '@fortawesome/free-solid-svg-icons'
import jwtInterceptor from '../shared/jwtInterceptor';
import AuthContext from '../shared/AuthContext';
import ProfileEditor from './ProfileEditor';
import SubmitButton from '../utilities/SubmitButton';

const Profile = () => {
  const { user, setUser } = useContext(AuthContext);

  const [msg, setMsg] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: '',
    name: '',
    telephone: '',
    address: '',
    addressImage: '',
    identityImage: '',
    isVerified: false
  });

  useEffect(() => {
    const fetchUser = async () => {
      if (user) {
        try {
          const userId = user.id
          const response = await jwtInterceptor.get(`/user/${userId}`);

          const addressImage = response?.data?.addressImage || '';
          const identityImage = response?.data?.identityImage || '';
    
          setUserData({
            email: response?.data?.email || '',
            name: response?.data?.name || '',
            telephone: response?.data?.telephone || '',
            address: response?.data?.address || '',
            addressImage,
            identityImage,
            isVerified: response?.data?.isVerified || false
          })
        } catch (error) {
          console.error(error.message);
        }
      }
    };

    fetchUser();
  }, [user.id]);

  const handleEdit = async (e, value = false) => {
    if (e) {
      e.preventDefault();
    }

    setIsEdit(value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setBtnLoading(true);
      const userId = user.id
      await jwtInterceptor.put(`/user/${userId}`, userData);

      setMsg('');
      setIsEdit(false);

      userData.id = userId
      setUser(userData);
      setBtnLoading(false);
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'Error';
      setMsg(errorMessage);
      setBtnLoading(false);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  return (
    <section className='section'>
      <Container>
        <Row className='mt-4'>
          <Col md={{ span: 8, offset: 2 }}>
            <Form onSubmit={handleSubmit}>
              {msg? (
                <Alert variant='danger'>
                  {msg}    
                </Alert>
              ) : ( <></> )}

              <ProfileEditor
                userData={userData}
                setUserData={setUserData}
                handleInputChange={handleInputChange}
                isEdit={isEdit}
              />
              
              {isEdit ? (
                <div className='text-center'>
                  <Button
                    variant='warning'
                    className='me-1'
                    onClick={(e) => { handleEdit(e, false) }}
                    size='sm'
                  >
                    <FontAwesomeIcon
                      icon={faBan}
                      className='me-1'
                    />
                    Batal
                  </Button>

                  <SubmitButton
                    loading={btnLoading}
                    type='submit'
                    variant='primary'
                    size='sm'
                    icon={faSave}
                    label='Simpan'
                  />
                </div>
              ) : (
                <div className='text-center'>
                  <Button
                    variant='secondary'
                    onClick={(e) => { handleEdit(e, true) }}
                    className='me-1'
                    size='sm'
                  >
                    <FontAwesomeIcon
                      icon={faPencil}
                      className='me-1'
                    />
                    Update Profil
                  </Button>
                </div>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Profile;
