import React, { useState } from 'react';
import { Button, Modal, Badge, Collapse, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faEye, faLocationDot, faList, faExclamationCircle, faX } from '@fortawesome/free-solid-svg-icons';
import AdminOrderEditor from './OrderEditor';
import jwtInterceptor from '../shared/jwtInterceptor';
import moment from 'moment';
import * as R from 'ramda';
import '../../index.css';
import SubmitButton from '../utilities/SubmitButton';

const HOST = process.env.REACT_APP_API_URL;
const FILES = process.env.REACT_APP_FILES_API_URL;

function AdminOrder ({ order, rowIndex, fetchOrders }) {
  const [showAddressModal, setShowAddressModal] = useState(false);
  const handleCloseAddressModal = () => setShowAddressModal(false);
  const handleShowAddressModal = () => setShowAddressModal(true);

  const [isDisabled, setIsDisabled] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const [showOrderEditor, setShowOrderEditor] = useState(false);
  const handleCloseOrderEditor = () => setShowOrderEditor(false);
  const handleShowOrderEditor = ({ isView = true }) => {
    setIsDisabled(isView);
    setShowOrderEditor(true)
  }

  const [openItems, setOpenItems] = useState(false);

  const handleCancel = async (e) => {
    e.preventDefault();

    if (order?.id) {
      const response = window.confirm('anda yakin untuk membatalkan order?');

      if (response) {
        try {
          setBtnLoading(true);
          await jwtInterceptor.delete(`/admin/orders/${order.id}`);
    
          fetchOrders();
          setBtnLoading(false);
        } catch (error) {
          console.error('Error deleting order: ' + error.message);
          setBtnLoading(false);
        }
      }
    }
  }

  // only incomplete custom products
  const hasCustomProducts = R.pipe(
    R.propOr([], 'orderItems'),
    R.map(orderItem => {
      const customProduct = Number(orderItem?.customProduct) || 0
      const priceFinal = Number(orderItem?.priceFinal) || 0
      if (customProduct === 1 && priceFinal === 0) {
        return true
      }
      
      return false
    }),
    R.includes(true)
  )(order)

  const freeShipping = Number(order?.freeShipping) || 0
  const shippingFee = Number(order?.shippingFee) || 0
  const hasCalculatedShippingFee = (
    (freeShipping === 1) ||
    (freeShipping === 0 && shippingFee > 0)
  )

  const lastStatus = R.pipe(
    R.propOr([], 'orderStatuses'),
    R.sortWith([
      R.descend(R.prop('createdAt')),
    ]),
    R.head
  )(order)

  const popover = (
    <Popover id='popover-basic'>
      <Popover.Body className='text-danger'>
        {hasCustomProducts && (
          <p className='mb-0'>Harga produk request belum diupdate!</p>
        )}

        {!hasCalculatedShippingFee && (
          <p className='mb-0'>Ongkos Kirim belum diupdate!</p>
        )}
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <tr key={order.id} className={rowIndex%2 === 0 ? 'table-secondary align-top' : 'align-top'}>
        <td>
          {(hasCustomProducts || !hasCalculatedShippingFee) && (
            <OverlayTrigger trigger='click' placement='auto-end' overlay={popover}>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className='text-danger'
              />
            </OverlayTrigger>
          )}
        </td>
        <td className='text-center'>{order.orderNumber}</td>
        <td>
          {order.name}
          <p className='mb-0 text-tiny'>({order.telephone})</p>
        </td>
        <td>
          {order.addressImage && (
            <Button
              size='sm'
              variant='secondary'
              onClick={handleShowAddressModal}
              className='btn-action me-1'
            >
              <FontAwesomeIcon
                icon={faLocationDot}
              />
            </Button>
          )}
          {order.address}
        </td>
        <td>
          <span className='fw-bold'>${order.total}</span>
          {Number(order?.freeShipping) === 1 ? (
            <p className='text-tiny text-success'>Gratis Ongkir</p>
          ) : (
            <>
              <p className='text-tiny mb-0'>Order: ${order.preTotal.toLocaleString()}</p>
              <p className='text-tiny mb-0'>Ongkir: ${order.shippingFee.toLocaleString()}</p>
            </>
          )}
        </td>
        <td className='text-center'>
          {lastStatus?.status === 'created' && (
            <Badge bg='primary'>Order Dibuat</Badge>
          )}

          {lastStatus?.status === 'processed' && (
            <Badge bg='warning'>Order Diproses</Badge>
          )}

          {lastStatus?.status === 'canceled' && (
            <Badge bg='danger'>Order Dibatalkan</Badge>
          )}

          {lastStatus?.status === 'done' && (
            <Badge bg='success'>Order Selesai</Badge>
          )}
          
          <p className='text-tiny mb-0'>
            {moment(lastStatus?.createdAt).format('YYYY-MM-DD hh:mm')}
          </p>

          {order?.deliveryPersonId && (
            <>
              <p className='mt-2 mb-0'>{order.deliveryPersonName}</p>
              <p className='mb-0 text-tiny'>({order.deliveryPersonTelephone})</p>
            </>
          )}
        </td>
        <td className='text-center'>
          <Button
            size='sm'
            variant='secondary'
            className='btn-action'
            onClick={() => handleShowOrderEditor({
              isView: true
            })}
          >
            <FontAwesomeIcon
              icon={faEye}
            />
          </Button>

          <Button
            size='sm'
            className='btn-action'
            onClick={() => handleShowOrderEditor({
              isView: false
            })}
          >
            <FontAwesomeIcon
              icon={faPen}
            />
          </Button>

          <SubmitButton
            loading={btnLoading}
            size='sm'
            variant='danger'
            className='btn-action'
            onClick={(e) => { handleCancel(e) }}
            icon={faX}
          />
          {/* <Button
            size='sm'
            variant='danger'
            className='btn-action'
            onClick={(e) => { handleCancel(e) }}
          >
            <FontAwesomeIcon
              icon={faX}
            />
          </Button> */}

          <Button
            size='sm'
            variant='secondary'
            aria-controls={`collapse-items-${order.id}`}
            aria-expanded={openItems}
            className='btn-action'
            onClick={() => setOpenItems(!openItems)}
          >
            <FontAwesomeIcon
              icon={faList}
            />
          </Button>
        </td>
      </tr>
      <Collapse in={openItems} id={`collapse-items-${order.id}`}>
        <tr className={rowIndex%2 === 0 ? 'table-secondary mb-1 text-small' : 'mb-1 text-small'}>
          <td></td>
          <td colSpan='99'>
            <div className='order-items'>
              <div className='d-block'>
                <div className='items fw-bold'>Produk</div>
                <div className='items fw-bold text-center'>Qty</div>
                <div className='items fw-bold text-end'>Harga</div>
                <div className='items fw-bold text-end'>Jumlah</div>
                <div className='items'></div>
              </div>
              {order?.orderItems && order.orderItems.map((orderItem) => (
                <div className='d-block' key={orderItem.id}>
                  <div className='items'>{orderItem.name}</div>
                  <div className='items text-center'>{orderItem.qty}</div>
                  <div className='items text-end'>${orderItem?.priceFinal?.toLocaleString()}</div>
                  <div className='items text-end'>${orderItem?.amount?.toLocaleString()}</div>
                  {(Number(orderItem?.customProduct) === 1 && Number(orderItem?.priceFinal) === 0) ? (
                    <div className='items text-danger'>*Harga Sementara</div>
                  ) : (
                    <div className='items'></div>
                  )}
                </div>
              ))}
            </div>
          </td>
        </tr>
      </Collapse>

      {/* address modal */}
      <Modal size='lg' show={showAddressModal} onHide={handleCloseAddressModal}>
        <Modal.Body>
          <img alt='address' src={`${FILES}/addresses/${order.addressImage}`} className='w-100' />
        </Modal.Body>
      </Modal>

      {/* order editor modal */}
      <Modal size='xl' show={showOrderEditor} onHide={handleCloseOrderEditor}>
        <Modal.Header closeButton>
          <Modal.Title>
            {order.orderNumber} ({order.name})
          </Modal.Title>
        </Modal.Header>

        <AdminOrderEditor
          orderId={order.id}
          isEdit={true}
          isDisabled={isDisabled}
          fetchOrders={fetchOrders}
          handleCloseOrderEditor={handleCloseOrderEditor}
        />
      </Modal>
    </>
  )
}

export default AdminOrder;