import React, { useContext, useState } from 'react';
import AuthContext from './shared/AuthContext';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import * as R from 'ramda';
import CheckoutProfile from './CheckoutProfile';
import '../index.css';

function Checkout() {
  const { cartItems, setCartItems } = useContext(AuthContext);
  const [showCheckoutProfile, setShowCheckoutProfile] = useState(false);

  const total = R.pipe(
    R.map(cartItem => {
      let price = cartItem?.price
      if (cartItem?.priceDiscount) {
        price = cartItem?.priceDiscount
      }

      return price * cartItem.qty
    }),
    R.sum
  )(cartItems);

  const hasCustomProducts = R.pipe(
    R.map(orderItem => {
      const customProduct = Number(orderItem?.customProduct) || 0
      const price = Number(orderItem?.price) || 0

      if (customProduct === 1 && price === 0) {
        return true
      }
      
      return false
    }),
    R.includes(true)
  )(cartItems)

  const increaseQty = (e, cartItem) => {
    e.preventDefault();
    
    const productId = cartItem.id;
    const newCartItems = R.map(cartItem => {
      if (cartItem.id === productId) {
        cartItem.qty++
      }

      return cartItem
    })(cartItems);

    localStorage.setItem('cart', JSON.stringify(newCartItems));
    setCartItems(newCartItems);
  }

  const decreaseQty = (e, cartItem) => {
    e.preventDefault();
    
    const productId = cartItem.id;
    const newCartItems = R.pipe(
      R.map(cartItem => {
        if (cartItem.id === productId) {
          cartItem.qty--

          if (cartItem.qty <= 0) {
            return {}
          }
        }

        return cartItem
      }),
      R.reject(R.isEmpty)
    )(cartItems);

    localStorage.setItem('cart', JSON.stringify(newCartItems));
    setCartItems(newCartItems);
  }

  const handleShowCheckoutProfile = () => {
    setShowCheckoutProfile(true);
  }

  return (
    <section className='section'>
      <Container>
        <Row>
          <Col lg={12}>
            <h3 className='text-center mb-5'>Pesanan Anda</h3>

            <Table>
              <thead>
                <tr>
                  <th>Produk</th>
                  <th className='text-center'>Qty</th>
                  <th className='text-end'>Harga</th>
                  <th className='text-end'>Jumlah</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((cartItem) => (
                  <tr key={cartItem.id} className='align-middle'>
                    <td className='w-75'>
                      {cartItem.name}
                      {cartItem?.customProduct === 1 && (
                        <p className='mb-0 text-tiny text-danger'>
                          *Harga sementara
                        </p>
                      )}
                    </td>
                    <td className='text-center'>
                      <div className='input-group w-px-120'>
                        <button
                          className='btn btn-outline-secondary'
                          type='button'
                          onClick={(e) => { decreaseQty(e, cartItem) }}
                        >
                          -
                        </button>

                        <input
                          type='text'
                          className='form-control text-center'
                          placeholder=''
                          aria-label='Example text with button addon'
                          aria-describedby='button-addon1'
                          value={cartItem.qty}
                          disabled={true}
                        />

                        <button
                          className='btn btn-outline-secondary'
                          type='button'
                          onClick={(e) => { increaseQty(e, cartItem) }}
                        >
                          +
                        </button>
                      </div>
                    </td>
                    <td className='text-end'>
                      ${cartItem.priceDiscount ? cartItem.priceDiscount.toLocaleString() : cartItem.price.toLocaleString()}
                    </td>
                    <td className='text-end fw-bold'>
                      ${cartItem.priceDiscount ? (cartItem.priceDiscount * cartItem.qty).toLocaleString() : (cartItem.price * cartItem.qty).toLocaleString()}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={3} className='border-bottom-0 text-end fw-bold'>Total</td>
                  <td className='border-bottom-0 text-end fw-bold'>${total.toLocaleString()}</td>
                </tr>
              </tbody>
            </Table>

            <div className='text-center'>
              {hasCustomProducts ? (
                <p className='text-tiny text-danger'>
                  Total belum termasuk biaya kirim dan biaya barang yang Anda request. Kami akan menginfokan kepada Anda lagi setelah harga terupdate.
                </p>
              ) : (
                <p className='text-tiny text-danger'>
                  Total belum termasuk biaya kirim. Kami akan menginfokan kepada Anda lagi setelah harga terupdate
                </p>
              )}

              <Button size='sm' variant='primary' href='/shop' className='me-1'>
                Tambah Produk Lain
              </Button>
              
              {!showCheckoutProfile && (
                <Button size='sm' variant='primary' onClick={handleShowCheckoutProfile}>
                  Lanjut Proses Pembelian
                </Button>
              )}
            </div>
          </Col>
        </Row>

        {showCheckoutProfile && (
          <CheckoutProfile />
        )}
      </Container>
    </section>
  );
}

export default Checkout;
