import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from './shared/AuthContext';
import { Row, Col, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import jwtInterceptor from './shared/jwtInterceptor';
import '../index.css';
import ProfileEditor from './users/ProfileEditor';
import SubmitButton from './utilities/SubmitButton';

const HOST = process.env.REACT_APP_API_URL;

function CheckoutProfile() {
  const { cartItems, setCartItems, user } = useContext(AuthContext);

  const navigate = useNavigate();
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: '',
    name: '',
    telephone: '',
    address: '',
    addressImage: '',
    identityImage: ''
  })

  useEffect(() => {
    const fetchUser = async () => {
      if (user) {
        try {
          setLoading(true);
          const userId = user.id
          const response = await jwtInterceptor.get(`/user/${userId}`);

          const addressImage = response?.data?.addressImage || '';
          const identityImage = response?.data?.identityImage || '';
    
          setUserData({
            email: response?.data?.email || '',
            name: response?.data?.name || '',
            telephone: response?.data?.telephone || '',
            address: response?.data?.address || '',
            addressImage,
            identityImage
          })
          setLoading(false);
        } catch (error) {
          console.error(error.message);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchUser();
  }, [user]);

  const handleCheckout = async () => {
    const checkoutData = {
      user: userData,
      cartItems: cartItems
    }

    try {
      setBtnLoading(true);
      const response = await axios.post(`${HOST}/order`, checkoutData)
      const orderNumber = response?.data?.orderNumber || ''

      setBtnLoading(false);
      if (orderNumber) {
        localStorage.removeItem('cart');
        setCartItems([]);

        navigate('/order-success', {
          state: {
            orderNumber: orderNumber
          }
        })
      }
    } catch (error) {
      const message = error?.response?.data?.message || 'Checkout Gagal. Silahkan Coba Kembali.';
      setMsg(message);
      setBtnLoading(false);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  return (
    <>
      <hr />

      <Row>
        <Col lg={12}>
          <h3 className='text-center mb-2'>Data Pengiriman</h3>
          {!user && (
            <>
              <p className='text-center mb-2'>
                Anda belum terdaftar/login sebagai member, Segera daftar/login untuk mempermudah proses pengisian data.
              </p>

              <div className='text-center mb-sm-2'>
                <Button
                  size='sm'
                  href='/register'
                  className='me-1'
                >
                  Daftar
                </Button>

                <Button
                  size='sm'
                  href='/login'
                >
                  Login
                </Button>
              </div>
            </>
          )}

          {loading && (
            <Row className='justify-content-center'>
              <span className='mt-3 loader'></span>
            </Row>
          )}

          {!loading && (
            <>
              <Form>
                {msg && (
                  <Alert variant='danger'>
                    {msg}    
                  </Alert>
                )}

                <ProfileEditor
                  userData={userData}
                  setUserData={setUserData}
                  handleInputChange={handleInputChange}
                  isEdit={true}
                  isCheckout={true}
                />
              </Form>

              <div className='d-grid gap-2'>
                <SubmitButton
                  loading={btnLoading}
                  variant='primary'
                  label='Pesan Sekarang'
                  onClick={handleCheckout}
                />
              </div>
            </>
          )}
        </Col>
      </Row>
    </>
  )
}

export default CheckoutProfile;
