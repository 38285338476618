import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../../index.css'

const CustomersRegisterSuccess = () => {
  return (
    <section className='section'>
      <Container>
        <Row>
          <Col xs={12} className='text-center'>
            <h3 className='my-3 text-success'>Registrasi Berhasil</h3>

            <p className='mb-2'>Silahkan tunggu sebentar.</p>
            <p className='mb-0'>Kami akan segera mem-verifikasi data Anda terlebih dahulu.</p>
            <p className='mb-0'>Kami akan mengkonfirmasi melalui email/nomor telepon yang Anda daftarkan, apabila semua data sesuai.</p>
            <p className='mb-3'>Terima kasih.</p>

            <Button
              href='/login'
              className='me-1'
            >
              Login
            </Button>

            <Button
              href='/'
              className='me-1'
            >
              Home
            </Button>

            <Button
              href='/shop'
            >
              Toko
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default CustomersRegisterSuccess;
