import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Table, Modal, Badge, Alert, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faEye, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import AdminProductEditor from './ProductEditor';
import jwtInterceptor from '../shared/jwtInterceptor';
import axios from 'axios';
import '../../index.css';
import SubmitButton from '../utilities/SubmitButton';

const HOST = process.env.REACT_APP_API_URL;
const FILES = process.env.REACT_APP_FILES_API_URL;

function AdminProducts () {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [isNewProduct, setIsNewProduct] = useState();
  const [isEdit, setIsEdit] = useState(true);
  const [showProductEditor, setShowProductEditor] = useState(false);
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filter, setFilter] = useState('');
  const [totals, setTotals] = useState(0);
  const [msg, setMsg] = useState('');
  const [editorMsg, setEditorMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [init, setInit] = useState(true);

  // fetch products
  useEffect(() => {
    if (filter !== '' || !init) {
      fetchPaginatedProducts();
    }
  }, [currentPage, filter]);

  const fetchPaginatedProducts = async () => {
    try {
      if (init) {
        setInit(false);
      }

      setLoading(true);
      const response = await jwtInterceptor.get(`/admin/products?page=${currentPage}&search=${filter}`);

      setProducts(response.data.products);
      setTotalPages(response.data.totalPages);
      setTotals(response.data.totals);
      setLoading(false);
    } catch (error) {
      const errorMessage = error?.response?.data?.message || '';
      setMsg(errorMessage);
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  const handleSearchInputChange = (e) => {
    const { name, value } = e.target;
    setFilter(value);
    setMsg('');
  }

  useEffect(() => {
    let timer = setTimeout(() => {
      fetchPaginatedProducts();
    }, 1000);

    return () => clearTimeout(timer);
  }, [filter]);

  // show/hide modal
  const handleCloseProductEditor = () => setShowProductEditor(false)
  const handleShowProductEditor = ({ isNewProduct = true, isView = false, productId, selectedProduct }) => {
    setIsNewProduct(isNewProduct);
    setEditorMsg('');

    if (productId) {
      setProduct(selectedProduct)
    } else {
      setProduct({
        name: '',
        price: '',
        priceDiscount: '',
        description: '',
        status: 'active'
      })
    }

    setIsEdit(!isView);
    setShowProductEditor(true)
  }

  // product data
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setBtnLoading(true);
      if (isNewProduct) {
        await jwtInterceptor.post('/admin/products', product);
      } else {
        await jwtInterceptor.put(`/admin/products/${product.id}`, product);
      }

      setBtnLoading(false);
      fetchPaginatedProducts();
      handleCloseProductEditor();
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'Error';
      setEditorMsg(errorMessage);
      setBtnLoading(false);
    }
  };

  const handleDelete = async ({ e, productId }) => {
    e.preventDefault();

    if (productId) {
      const response = window.confirm('anda yakin untuk menghapus?');

      if (response) {
        try {
          setBtnLoading(true);
          await jwtInterceptor.delete(`/admin/products/${productId}`);
    
          setBtnLoading(false);
          fetchPaginatedProducts();
        } catch (error) {
          const errorMessage = error?.response?.data?.message || 'Error';
          setMsg(errorMessage);
          setBtnLoading(false);
        }
      }
    }
  }

  const imageHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    formData.append('type', 'addresses')

    const config = {
      headers: {
        Accept: 'multipart/form-data'
      }
    };

    try {
      const response = await axios.post(`${HOST}/upload/products`, formData, config);
      const filename = response.data;

      const newProduct = product;
      newProduct.productImage = filename;
      setProduct(newProduct)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <section className='section'>
      <Container>
        <Row>
          <Col xs={8}>
            <Button
              size='sm'
              onClick={() => handleShowProductEditor({
                isNewProduct: true,
                productId: '',
                selectedProduct: ''
              })}
            >
              Tambah Produk Baru
            </Button>
          </Col>
          <Col xs={4} className='ms-auto'>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                />
              </InputGroup.Text>

              <Form.Control
                type='text'
                name='filter'
                value={filter}
                onChange={(e) => { handleSearchInputChange(e) }}
                placeholder='Cari Produk...'
                size='sm'
              />
            </InputGroup>
          </Col>
        </Row>

        <hr />

        {loading && (
          <Row className='justify-content-center'>
            <span className='mt-3 loader'></span>
          </Row>
        )}

        {msg && (
          <Row>
            <Col xs={12}>
              <Alert variant='danger'>
                {msg}    
              </Alert>
            </Col>
          </Row>
        )}

        {!loading && (
          <>
            <Table responsive>
              <thead>
                <tr>
                  <th className='w-10 text-center'></th>
                  <th className='w-10 text-center'>Nama</th>
                  <th className='w-40 text-center'>Deskripsi</th>
                  <th className='text-center'>Harga</th>
                  <th className='text-center'>Status</th>
                  <th className='w-20'></th>
                </tr>
                {products.map((product) => (
                  <tr key={product.id} className='align-top'>
                    <td>
                      <div className='img-product-admin'>
                      {product?.productImage && (
                        <img alt={product.name} src={`${FILES}/products/${product.productImage}`} className='w-100' />
                      )}
                      </div>
                    </td>
                    <td>{product.name}</td>
                    <td>
                      <div dangerouslySetInnerHTML={{__html: product.description}} />
                    </td>
                    <td className='text-end'>
                      {
                        product.priceDiscount ? (
                          <>
                            <del>${product.price.toLocaleString()} </del>
                            <span>${product.priceDiscount.toLocaleString()}</span>
                          </>
                        ) : (
                          <span>${product.price.toLocaleString()}</span>
                        )
                      }
                      
                    </td>
                    <td className='text-center'>
                      {
                        product.status === 'active' ? (
                          <Badge bg='success'>aktif</Badge>
                        ) : (
                          <Badge bg='danger'>inaktif</Badge>
                        )
                      }
                    </td>
                    <td className='text-center'>
                      <Button
                        size='sm'
                        variant='secondary'
                        className='btn-action'
                        onClick={() => handleShowProductEditor({
                          isNewProduct: false,
                          isView: true,
                          productId: product.id,
                          selectedProduct: product
                        })}
                      >
                        <FontAwesomeIcon
                          icon={faEye}
                        />
                      </Button>

                      <Button
                        size='sm'
                        className='btn-action'
                        onClick={() => handleShowProductEditor({
                          isNewProduct: false,
                          productId: product.id,
                          selectedProduct: product
                        })}
                      >
                        <FontAwesomeIcon
                          icon={faPen}
                        />
                      </Button>

                      <SubmitButton
                        loading={btnLoading}
                        size='sm'
                        variant='danger'
                        className='btn-action'
                        onClick={(e) => { handleDelete({
                          e,
                          productId: product.id
                        }) }}
                        icon={faTrash}
                      />
                    </td>
                  </tr>
                ))}
              </thead>
            </Table>

            <nav className='d-flex justify-content-center mt-3'>
              <ul className='pagination'>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li className={(index + 1) === currentPage ? 'page-item active' : 'page-item'} key={index + 1}>
                    <button className='page-link' onClick={() => handlePageChange(index + 1)}>
                      {index + 1}  
                    </button>
                  </li>
                ))}
              </ul>
            </nav>

            <hr />
          </>
        )}
      </Container>

      <Modal size='lg' show={showProductEditor} onHide={handleCloseProductEditor}>
        <Modal.Header closeButton>
          <Modal.Title>
            {product.name ? product.name : 'Produk Baru'}
          </Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            {editorMsg && (
              <Alert variant='danger'>
                {editorMsg}
              </Alert>
            )}

            <AdminProductEditor
              product={product}
              isEdit={isEdit}
              onchange={(e) => { handleInputChange(e) }}
              imageHandler={imageHandler}
            />
          </Modal.Body>

          {isEdit && (
            <Modal.Footer>
              <Button size='sm' className='me-auto' variant="secondary" onClick={handleCloseProductEditor}>
                Batal
              </Button>

              <SubmitButton
                loading={btnLoading}
                size='sm'
                type='submit'
                variant='primary'
                label='Simpan'
              />
            </Modal.Footer>
          )}
        </Form>
      </Modal>
    </section>
  );
}

export default AdminProducts;
