import { useContext } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import logo from '../../logo.svg';
import '../../index.css';
import AuthContext from '../shared/AuthContext';
import Footer from '../Footer';
import CartBtn from './CartBtn';

const Layout = ({ children }) => {
  const { user, logout, cartItems } = useContext(AuthContext);
  return (
    <>
      <Navbar fixed='top' bg='dark' data-bs-theme='dark'>
        <Container>
          <Navbar.Brand href='/'>
            {/* <img
              alt='logo'
              src={logo}
              height='30'
              className='d-inline-block align-top'
            />{' '} */}
            MAIMAI SHOP
          </Navbar.Brand>

          <Nav className='me-auto'>
            <div className='d-none d-md-block'>
              <Nav.Link href='/'>Home</Nav.Link>
            </div>
            <Nav.Link href='/shop'>Toko</Nav.Link>
          </Nav>

          <Nav className='ml-auto'>
            <CartBtn
              cartItems={cartItems}
            />

            {!user ? (
              <Nav.Link href='/login'>Login</Nav.Link>
            ) : (
              <NavDropdown
                title={user.name}
                align='end'
                size='sm'
              >
                <NavDropdown.Item href='/'>Home</NavDropdown.Item>
                {(user.role === 'admin' || user.role === 'super') && (
                  <>
                    <NavDropdown.Item href='/admin/users'>Atur Pelanggan</NavDropdown.Item>
                    <NavDropdown.Item href='/admin/super'>Atur Admin</NavDropdown.Item>
                    <NavDropdown.Item href='/admin/products'>Atur Produk</NavDropdown.Item>
                    <NavDropdown.Item href='/admin/orders'>Atur Pesanan</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href='/orders'>Pesanan</NavDropdown.Item>
                    <NavDropdown.Item href='/change-password'>Ubah Password</NavDropdown.Item>
                  </>
                )}
                
                {user.role !== 'admin' && (
                  <>
                    <NavDropdown.Item href='/profile'>Profile</NavDropdown.Item>
                    <NavDropdown.Item href='/change-password'>Ubah Password</NavDropdown.Item>
                    <NavDropdown.Item href='/orders'>Pesanan</NavDropdown.Item>
                  </>
                )}

                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logout}>Keluar</NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Container>
      </Navbar>

      {children}

      <Footer />
    </>
  );
};
 
export default Layout;