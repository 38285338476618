import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, InputGroup, Alert } from 'react-bootstrap';
import ShopProduct from './ShopProduct';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const HOST = process.env.REACT_APP_API_URL;

function Shop() {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filter, setFilter] = useState('');
  const [totals, setTotals] = useState(0);
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [init, setInit] = useState(true);

  useEffect(() => {
    if (filter !== '' || !init) {
      fetchPaginatedProducts();
    }
  }, [currentPage, filter]);

  const fetchPaginatedProducts = async () => {
    try {
      if (init) {
        setInit(false);
      }

      setLoading(true);
      const response = await axios.get(`${HOST}/products?page=${currentPage}&search=${filter}`);

      setProducts(response.data.products);
      setTotalPages(response.data.totalPages);
      setTotals(response.data.totals);
      setLoading(false);
    } catch (error) {
      const errorMessage = error?.response?.data?.message || '';
      setMsg(errorMessage);
      setLoading(false);
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  const handleSearchInputChange = (e) => {
    const { name, value } = e.target;
    setFilter(value);
    setMsg('');
  }

  useEffect(() => {
    let timer = setTimeout(() => {
      fetchPaginatedProducts();
    }, 1000);

    return () => clearTimeout(timer);
  }, [filter]);

  return (
    <>
      <section className='section'>
        <Container>
          <Row>
            <Col xs={12}>
              <header className='d-flex align-items-center border-bottom mb-4 pb-3'>
                <strong className='d-block py-2'>{totals} Produk Ditemukan</strong>

                <div className='ms-auto'>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                      />
                    </InputGroup.Text>

                    <Form.Control
                      type='text'
                      name='filter'
                      value={filter}
                      onChange={(e) => { handleSearchInputChange(e) }}
                      placeholder='Cari Produk...'
                    />
                  </InputGroup>
                </div>
              </header>
            </Col>
          </Row>

          <Row className='justify-content-center mb-3'>
            <ShopProduct
              isCustomProduct={true}
            />

            {msg && (
              <Col xs={12} className='mt-2'>
                <Alert variant='danger'>
                  {msg}    
                </Alert>
              </Col>
            )}

            {loading && (
              <span className='mt-3 loader'></span>
            )}
          </Row>

          {!loading && (
            <>
              {products && products.map((product) => (
                <div key={product.id} className='row justify-content-center mb-3'>
                  <ShopProduct
                    product={product}
                  />
                </div>
              ))}

              <hr />

              <nav className='d-flex justify-content-center mt-3'>
                <ul className='pagination'>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li className={(index + 1) === currentPage ? 'page-item active' : 'page-item'} key={index + 1}>
                      <button className='page-link' onClick={() => handlePageChange(index + 1)}>
                        {index + 1}  
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </>
          )}
        </Container>
      </section>
    </>
  );
}

export default Shop;
