import React, { useEffect, useState } from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../../index.css';

const HOST = process.env.REACT_APP_API_URL;
const FILES = process.env.REACT_APP_FILES_API_URL;

function AdminProductEditor ({ product, isEdit, onchange, imageHandler }) {
  const [productImage, setProductImage] = useState('');

  useEffect(() => {
    if (product?.productImage) {
      const filePath = `${FILES}/products/${product.productImage}`;
      setProductImage(filePath)
    }
  }, [product?.productImage])

  return (
    <>
      <FloatingLabel
        label='Nama'
        className='mb-3'
      >
        <Form.Control
          type='text'
          name='name'
          value={product.name}
          onChange={ (e) => { onchange(e) } }
          disabled={!isEdit}
          placeholder='Nama'
        />
      </FloatingLabel>

      <div
        className={!isEdit ? 'form-custom-bordered mb-3 disabled' : 'form-custom-bordered mb-3'}
      >
        <p className='mb-0 text-tiny label-img'>Deskripsi</p>

        {!isEdit ? (
          product.description
        ) : (
          <CKEditor
            editor={ ClassicEditor }
            data={product.description}
            onChange={ (e, editor) => {
              e.target = {
                name: 'description',
                value: editor.getData()
              }

              onchange(e)
            } }
          />
        )}
      </div>

      <FloatingLabel
        label='Harga'
        className='mb-3'
      >
        <Form.Control
          type='text'
          name='price'
          value={product.price}
          onChange={ (e) => { onchange(e) } }
          disabled={!isEdit}
          placeholder='Harga'
        />
      </FloatingLabel>

      <FloatingLabel
        label='Harga Diskon'
        className='mb-3'
      >
        <Form.Control
          type='text'
          name='priceDiscount'
          value={product.priceDiscount}
          onChange={ (e) => { onchange(e) } }
          disabled={!isEdit}
          placeholder='Harga Diskon'
        />
      </FloatingLabel>

      <FloatingLabel
        label='Status'
        className='mb-3'
      >
        <Form.Select
          name='status'
          value={product.status}
          onChange={ (e) => { onchange(e) } }
          disabled={!isEdit}
        >
          <option value='active'>Aktif</option>
          <option value='inactive'>Inaktif</option>
        </Form.Select>
      </FloatingLabel>

      <Form.Group className='mb-3'>
        <Form.Control
          type='file'
          accept='image/*'
          multiple={false}
          onChange={(e) => { imageHandler(e) }}
          disabled={!isEdit}
        />

        {productImage && (
          <img alt={product.name} src={productImage} className='img-profile mt-2' />
        )}
      </Form.Group>
    </>
  );
}

export default AdminProductEditor;
