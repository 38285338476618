import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../index.css'
import '../index.scss'
import Logo from '../logo.jpg'

function About() {
  return (
    <section className='section'>
      <Container>
        <Row>
          <Col md={12} className='text-center'>
            <h2>Tentang Kami</h2>

            <hr />

            <img src={Logo} alt='MaiMaiShop' className='mb-3' />

            <p>Selamat datang di <span className='fw-bold'>MaiMaiShop</span>, tempat di mana kebutuhan Anda menjadi prioritas utama. Kami adalah tim yang berdedikasi untuk memberikan pengalaman belanja online terbaik kepada Anda, masyarakat Indonesia yang beragam.</p>

            <p>Misi kami sederhana: Kami ingin memberikan kemudahan, kenyamanan, dan akses ke berbagai produk yang Anda butuhkan dalam kehidupan sehari-hari. Dengan keyakinan bahwa belanja haruslah menyenangkan dan mudah, kami berkomitmen untuk menyediakan layanan yang luar biasa.</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default About;
