import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Form, Alert, FloatingLabel } from 'react-bootstrap';
import AuthContext from '../shared/AuthContext'
import '../../index.css';
import CryptoJS from 'crypto-js';
import * as R from 'ramda';
import SubmitButton from '../utilities/SubmitButton';

function CustomersLogin() {
  const [msg, setMsg] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);

  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
  });

  const { login } = useContext(AuthContext)

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setBtnLoading(true);
      const newLoginData = R.clone(loginData);
      newLoginData.password = CryptoJS.AES.encrypt(loginData.password, process.env.REACT_APP_SECRETKEY).toString();

      await login(newLoginData)
      setBtnLoading(false);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.message);
      }
      setBtnLoading(false);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  return (
    <section className='section'>
      <Container>
        <Row className='mt-4'>
          <Col md={{ span: 8, offset: 2 }}>
            <Form onSubmit={handleSubmit}>
              {msg? (
                <Alert variant='danger'>
                  {msg}    
                </Alert>
              ) : ( <></> )}

              <FloatingLabel
                label='Email'
                className='mb-3'
              >
                <Form.Control
                  type='text'
                  name='email'
                  value={loginData.email}
                  onChange={handleInputChange}
                  placeholder='Email'
                />
              </FloatingLabel>

              <FloatingLabel
                label='Password'
                className='mb-3'
              >
                <Form.Control
                  type='password'
                  name='password'
                  value={loginData.password}
                  onChange={handleInputChange}
                  placeholder='Password'
                />
              </FloatingLabel>

              <div className='d-grid gap-2'>
                <SubmitButton
                  loading={btnLoading}
                  label='Login'
                  type='submit'
                />
              </div>

              <p className='mt-2 text-center'>
                <span>Belum punya akun? </span>
                <Link to='/register'>Daftar</Link>
              </p>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default CustomersLogin;
