import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Alert, FloatingLabel } from 'react-bootstrap';
import '../../index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faSave } from '@fortawesome/free-solid-svg-icons'
import jwtInterceptor from '../shared/jwtInterceptor';
import AuthContext from '../shared/AuthContext';
import CryptoJS from 'crypto-js';

const initFormData = {
  oldPassword: '',
  newPassword: '',
  confNewPassword: ''
}

const UserPassword = () => {
  const { user, setUser } = useContext(AuthContext);

  const navigate = useNavigate();

  const [msg, setMsg] = useState('');
  const [userData, setUserData] = useState(initFormData);

  useEffect(() => {
    setUserData(initFormData)
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const oldPassword = CryptoJS.AES.encrypt(userData.oldPassword, process.env.REACT_APP_SECRETKEY).toString();
    const newPassword = CryptoJS.AES.encrypt(userData.newPassword, process.env.REACT_APP_SECRETKEY).toString();
    const confNewPassword = CryptoJS.AES.encrypt(userData.confNewPassword, process.env.REACT_APP_SECRETKEY).toString();

    try {
      const userId = user.id
      await jwtInterceptor.put(`/user/change-password/${userId}`, {
        oldPassword,
        newPassword,
        confNewPassword
      });

      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      setUser(null);

      navigate('/login');
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'Error';
      setMsg(errorMessage);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  return (
    <section className='section'>
      <Container>
        <Row className='mt-4'>
          <Col md={{ span: 8, offset: 2 }}>
            <Form onSubmit={handleSubmit}>
              {msg? (
                <Alert variant='danger'>
                  {msg}    
                </Alert>
              ) : ( <></> )}

              <FloatingLabel
                label='Password Lama'
                className='mb-3'
              >
                <Form.Control
                  type='password'
                  name='oldPassword'
                  value={userData.oldPassword}
                  onChange={handleInputChange}
                  placeholder='Password Lama'
                />
              </FloatingLabel>

              <FloatingLabel
                label='Password Baru'
                className='mb-3'
              >
                <Form.Control
                  type='password'
                  name='newPassword'
                  value={userData.newPassword}
                  onChange={handleInputChange}
                  placeholder='Password Baru'
                />
              </FloatingLabel>

              <FloatingLabel
                label='Ulangi Password Baru'
                className='mb-3'
              >
                <Form.Control
                  type='password'
                  name='confNewPassword'
                  value={userData.confNewPassword}
                  onChange={handleInputChange}
                  placeholder='Ulangi Password Baru'
                />
              </FloatingLabel>
              
              <div className='text-center'>
                <Button
                  variant='warning'
                  className='me-1'
                  href='/profile'
                  size='sm'
                >
                  <FontAwesomeIcon
                    icon={faBan}
                    className='me-1'
                  />
                  Batal
                </Button>

                <Button
                  type='submit'
                  variant='primary'
                  size='sm'
                >
                  <FontAwesomeIcon
                    icon={faSave}
                    className='me-1'
                  />
                  Simpan
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default UserPassword;
