import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Table, InputGroup, Alert } from 'react-bootstrap';
import AdminOrder from './Order';
import jwtInterceptor from '../shared/jwtInterceptor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import '../../index.css';

function AdminOrders () {
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filter, setFilter] = useState('');
  const [totals, setTotals] = useState(0);
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [init, setInit] = useState(true);

  // fetch orders
  useEffect(() => {
    if (filter !== '' || !init) {
      fetchPaginatedOrders();
    }
  }, [currentPage, filter]);

  const fetchPaginatedOrders = async () => {
    try {
      if (init) {
        setInit(false);
      }

      setLoading(true);
      const response = await jwtInterceptor.get(`/admin/orders?page=${currentPage}&search=${filter}`);

      setOrders(response.data.orders);
      setTotalPages(response.data.totalPages);
      setTotals(response.data.totals);
      setLoading(false);
    } catch (error) {
      const errorMessage = error?.response?.data?.message || '';
      setMsg(errorMessage);
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  const handleSearchInputChange = (e) => {
    const { value } = e.target;
    setFilter(value);
    setMsg('');
  }

  useEffect(() => {
    let timer = setTimeout(() => {
      fetchPaginatedOrders();
    }, 1000);

    return () => clearTimeout(timer);
  }, [filter]);

  return (
    <section className='section'>
      <Container>
        <Row>
          <Col xs={8}></Col>
          <Col xs={4} className='ms-auto'>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                />
              </InputGroup.Text>

              <Form.Control
                type='text'
                name='filter'
                value={filter}
                onChange={(e) => { handleSearchInputChange(e) }}
                placeholder='Cari Order...'
                size='sm'
              />
            </InputGroup>
          </Col>
        </Row>

        <hr />

        {loading && (
          <Row className='justify-content-center'>
            <span className='mt-3 loader'></span>
          </Row>
        )}

        {msg && (
          <Row>
            <Col xs={12}>
              <Alert variant='danger'>
                {msg}    
              </Alert>
            </Col>
          </Row>
        )}

        {!loading && (
          <>
            <Table responsive>
              <thead>
                <tr>
                  <th></th>
                  <th className='text-center'>Order</th>
                  <th className='text-center'>Pelanggan</th>
                  <th className='text-center'>Alamat</th>
                  <th className='text-center'>Total</th>
                  <th className='text-center'>Status</th>
                  <th className='text-center'></th>
                </tr>
                {orders.map((order, rowIndex) => (
                  <AdminOrder
                    key={order.id}
                    order={order}
                    rowIndex={rowIndex}
                    fetchOrders={fetchPaginatedOrders}
                  />
                ))}
              </thead>
            </Table>

            <nav className='d-flex justify-content-center mt-3'>
              <ul className='pagination'>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li className={(index + 1) === currentPage ? 'page-item active' : 'page-item'} key={index + 1}>
                    <button className='page-link' onClick={() => handlePageChange(index + 1)}>
                      {index + 1}  
                    </button>
                  </li>
                ))}
              </ul>
            </nav>

            <hr />
          </>
        )}
      </Container>
    </section>
  );
}

export default AdminOrders;
