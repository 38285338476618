import React, { useState, useEffect } from 'react';
import { Form, Button, Table, FloatingLabel, Modal, Alert, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import jwtInterceptor from '../shared/jwtInterceptor';
import * as R from 'ramda';
import '../../index.css';
import SubmitButton from '../utilities/SubmitButton';

const initialOrder = {
  name: '',
  email: '',
  telephone: '',
  address: '',
  freeShipping: 0,
  shippingFee: 0,
  deliveryPersonId: '',
  status: 'created'
}

function AdminOrderEditor ({ orderId, isEdit = false, isNew = false, isDisabled = true, fetchOrders, handleCloseOrderEditor }) {
  const [order, setOrder] = useState(initialOrder);
  const [adminUsers, setAdminUsers] = useState([]);
  const [editorMsg, setEditorMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const fetchPaginatedUsers = async () => {
    try {
      const response = await jwtInterceptor.get(`/admin/users/admin?limit=100`);

      setAdminUsers(response.data.users);
    } catch (error) {
      const errorMessage = error?.response?.data?.message || '';
      setEditorMsg(errorMessage);
    }
  };

  const fetchOrder = async () => {
    setLoading(true);
    if (orderId) {
      try {
        const response = await jwtInterceptor.get(`/admin/orders/${orderId}`);

        setOrder(response.data);
      } catch (error) {
        const errorMessage = error?.response?.data?.message || '';
        setEditorMsg(errorMessage);
      }
    } else {
      setOrder(initialOrder)
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchOrder();
    fetchPaginatedUsers();
  }, [orderId])

  const calculateOrder = (order) => {
    order.preTotal = R.pipe(
      R.propOr([], 'orderItems'),
      R.reject(R.propEq(true, 'isRemoved')),
      R.map(orderItem => {
       const priceFinal = orderItem?.priceFinal || orderItem?.priceDiscount || orderItem?.price || 0
       const qty = orderItem?.qty || 0

       return Number(priceFinal * qty) || 0
      }),
      R.sum
    )(order);

    let shippingFee = Number(order?.shippingFee)
    if (Number(order.freeShipping) === 1) {
      shippingFee = 0;
    }

    order.total = Number(order?.preTotal) + shippingFee;
    order.shippingFee = shippingFee

    return order
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let newOrder = {...order};
    newOrder[name] = value;

    newOrder = calculateOrder(newOrder);

    if (newOrder?.deliveryPersonId) {
      const selectedPerson = R.find(
        R.propEq(newOrder?.deliveryPersonId, 'id')
      )(adminUsers) || {}

      newOrder.deliveryPersonName = selectedPerson?.name || ''
      newOrder.deliveryPersonTelephone = selectedPerson?.telephone || ''
    }

    setOrder(newOrder);
  };

  const handleItemsInputChange = (e, orderItemId, isRemove = false) => {
    const { name, value } = e.target;

    let newOrder = {...order}
    newOrder.orderItems = R.pipe(
      R.propOr([], 'orderItems'),
      R.map(orderItem => {
        if (orderItem.id === orderItemId) {
          if (isRemove) {
            orderItem.isRemoved = true;
          }

          orderItem[name] = value;
          orderItem.amount = Number(orderItem?.qty) * Number(orderItem?.priceFinal);
        }

        return orderItem;
      }),
      R.reject(R.equals(false))
    )(newOrder);

    newOrder = calculateOrder(newOrder);

    setOrder(newOrder);
  }

  const handleAddOrderItem = (e) => {
    e.preventDefault();

    let newOrder = {...order}
    newOrder.orderItems.push({
      name: '',
      priceFinal: 0,
      amount: 0,
      qty: 0,
      customProduct: 1,
      isDeleted: 0,
      orderId: newOrder.id
    })

    setOrder(newOrder);
  }

  const handleSubmit = async (e, newOrder) => {
    e.preventDefault();

    setBtnLoading(true);
    try {
      await jwtInterceptor.put(`/admin/orders/${newOrder.id}`, newOrder);

      fetchOrders();
      handleCloseOrderEditor();
    } catch (error) {
      const errorMessage = error?.response?.data?.message || '';
      setEditorMsg(errorMessage);
    }
    setBtnLoading(false);
  };

  const warningSign = (
    <FontAwesomeIcon
      icon={faExclamationCircle}
      className='text-danger'
    />
  )

  return (
    <>
      <Modal.Body>
        {loading && (
          <Row className='justify-content-center'>
            <span className='mt-3 loader'></span>
          </Row>
        )}

        {editorMsg && (
          <Alert variant='danger'>
            {editorMsg}
          </Alert>
        )}

        {!loading && (
          <>
            <FloatingLabel
              label='Kurir'
              className='mb-3'
            >
              <Form.Select
                name='deliveryPersonId'
                value={order.deliveryPersonId}
                onChange={(e) => { handleInputChange(e) }}
                disabled={isDisabled}
              >
                <option value='' disabled>Pilih Kurir</option>
                {adminUsers && adminUsers.map((adminUser) => (
                  <option key={adminUser.id} value={adminUser.id}>{adminUser.name} ({adminUser.telephone})</option>
                ))}
              </Form.Select>
            </FloatingLabel>

            <hr />

            <FloatingLabel
              label='Nama'
              className='mb-3'
            >
              <Form.Control
                type='text'
                name='name'
                value={order.name}
                onChange={(e) => { handleInputChange(e) }}
                disabled={!isNew}
                placeholder='Nama'
              />
            </FloatingLabel>

            <FloatingLabel
              label='Email'
              className='mb-3'
            >
              <Form.Control
                type='text'
                name='email'
                value={order.email}
                onChange={(e) => { handleInputChange(e) }}
                disabled={!isNew}
                placeholder='Email'
              />
            </FloatingLabel>

            <FloatingLabel
              label='Telepon'
              className='mb-3'
            >
              <Form.Control
                type='text'
                name='telephone'
                value={order.telephone}
                onChange={(e) => { handleInputChange(e) }}
                disabled={!isNew}
                placeholder='Telepon'
              />
            </FloatingLabel>

            <FloatingLabel
              label='Address'
              className='mb-3'
            >
              <Form.Control
                type='text'
                name='address'
                value={order.address}
                onChange={(e) => { handleInputChange(e) }}
                disabled={isDisabled}
                placeholder='Address'
              />
            </FloatingLabel>

            <FloatingLabel
              label='Gratis Biaya Kirim'
              className='mb-3'
            >
              <Form.Select
                name='freeShipping'
                value={order.freeShipping}
                onChange={(e) => { handleInputChange(e) }}
                disabled={isDisabled}
              >
                <option value='0'>Tidak</option>
                <option value='1'>Ya</option>
              </Form.Select>
            </FloatingLabel>

            {Number(order.freeShipping) === 0 && (
              <FloatingLabel
                label='Biaya Kirim'
                className='mb-3'
              >
                <Form.Control
                  type='text'
                  name='shippingFee'
                  value={order.shippingFee}
                  onChange={(e) => { handleInputChange(e) }}
                  disabled={isDisabled}
                  placeholder='Biaya Kirim'
                />
              </FloatingLabel>
            )}

            <FloatingLabel
              label='Status'
              className='mb-3'
            >
              <Form.Select
                name='status'
                value={order.status}
                onChange={(e) => { handleInputChange(e) }}
                disabled={isDisabled}
              >
                <option value='created'>Order Dibuat</option>
                <option value='canceled'>Order Dibatalkan</option>
                <option value='processed'>Order Diproses</option>
                <option value='done'>Order Selesai</option>
              </Form.Select>
            </FloatingLabel>

            {order?.orderItems && (
              <Table>
                <thead>
                  <tr>
                    <th></th>
                    <th className='px-1'>Produk</th>
                    <th className='px-1 text-center'>Qty</th>
                    <th className='px-1 text-end'>Harga</th>
                    <th className='px-1 text-end'>Jumlah</th>
                    {!isDisabled && (
                      <th></th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {order?.orderItems && order?.orderItems?.map((orderItem) => (
                    <>
                      {(!orderItem?.isRemoved && !orderItem?.isDeleted) && (
                        <tr key={orderItem.id}>
                          <th>
                            {Number(orderItem?.customProduct) === 1 && Number(orderItem?.priceFinal) === 0 && (
                              warningSign
                            )}
                          </th>
                          <td className='p-1 w-50'>
                            <Form.Control
                              type='text'
                              name='name'
                              value={orderItem.name}
                              onChange={(e) => { handleItemsInputChange(e, orderItem.id) }}
                              disabled={isDisabled}
                              placeholder='Nama'
                            />
                          </td>
                          <td className='p-1'>
                            <Form.Control
                              type='text'
                              name='qty'
                              value={orderItem.qty}
                              onChange={(e) => { handleItemsInputChange(e, orderItem.id) }}
                              disabled={isDisabled}
                              placeholder='Qty'
                              className='text-center'
                            />
                          </td>
                          <td className='p-1'>
                            <Form.Control
                              type='text'
                              name='priceFinal'
                              value={orderItem.priceFinal}
                              onChange={(e) => { handleItemsInputChange(e, orderItem.id) }}
                              disabled={isDisabled}
                              placeholder='Harga'
                              className='text-end'
                            />
                          </td>
                          <td className='p-1 text-end align-middle'>
                            ${orderItem?.amount?.toLocaleString()}
                          </td>
                          {!isDisabled && (
                            <td>
                              {orderItem?.isRemoved}
                              <Button
                                size='sm'
                                variant='danger'
                                disabled={isDisabled}
                                onClick={(e) => { handleItemsInputChange(e, orderItem.id, true) }}
                                className='btn-action'
                              >
                                <FontAwesomeIcon
                                  icon={faX}
                                />
                              </Button>
                            </td>
                          )}
                        </tr>
                      )}
                    </>
                  ))}
                  <tr>
                    <td className='border-bottom-0 p-1'></td>
                    <td rowSpan='3' className='border-bottom-0 p-1'>
                      {!isDisabled && (
                        <Button
                          type='submit'
                          variant='primary'
                          size='sm'
                          onClick={(e) => { handleAddOrderItem(e) }}
                        >
                          Tambah Produk
                        </Button>
                      )}
                    </td>
                    <td className='border-bottom-0 p-1 text-end fw-bold' colSpan='2'>Subtotal</td>
                    <td className='border-bottom-0 p-1 text-end'>${order?.preTotal?.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td className='border-bottom-0 p-1 text-end fw-bold' colSpan='4'>Ongkir</td>
                    <td className='border-bottom-0 p-1 text-end'>${order?.shippingFee?.toLocaleString()}</td>
                    <td className='border-bottom-0 p-1'>
                      {Number(order?.freeShipping) === 0 && Number(order?.shippingFee) === 0 && (
                        warningSign
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className='border-bottom-0 p-1 text-end fw-bold' colSpan='4'>Total</td>
                    <td className='border-bottom-0 p-1 text-end'>${order?.total?.toLocaleString()}</td>
                  </tr>
                </tbody>
              </Table>
            )}
          </>
        )}
      </Modal.Body>

      {!isDisabled && (
        <>
          <Modal.Footer>
            <SubmitButton
              loading={btnLoading}
              label='Simpan'
              type='submit'
              variant='primary'
              onClick={(e) => { handleSubmit(e, order) }}
            />
            {/* <Button
              type='submit'
              variant='primary'
              onClick={(e) => { handleSubmit(e, order) }}
            >
              Simpan
            </Button> */}
          </Modal.Footer>
        </>
      )}
    </>
  )
}

export default AdminOrderEditor;
