import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
 
import AuthContext from './AuthContext';
 
const ProtectedRoute = ({ children, accessBy, role }) => {
  const { user } = useContext(AuthContext);

  if (accessBy === 'non-authenticated') {
    if (!user) {
      return children;
    }
  } else if (accessBy === 'authenticated') {
    if (user) {
      if (role && user.role === role) {
        return children
      }
      if(!role) {
        return children;
      }
    }
  }
 
  return <Navigate to='/'></Navigate>;
};
 
export default ProtectedRoute;
