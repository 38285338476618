import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Form, Alert, FloatingLabel } from 'react-bootstrap';
import '../../index.css'
import CryptoJS from 'crypto-js';
import * as R from 'ramda';
import SubmitButton from '../utilities/SubmitButton';

const HOST = process.env.REACT_APP_API_URL;
const FILES = process.env.REACT_APP_FILES_API_URL;

const CustomersRegister = () => {
  const navigate = useNavigate();
  const [msg, setMsg] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [identityImage, setIdentityImage] = useState('');
  const [addressImage, setAddressImage] = useState('');
  const [registerData, setRegisterData] = useState({
    email: '',
    password: '',
    confPassword: '',
    name: '',
    telephone: '',
    address: '',
    addressImage: '',
    identityImage: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setBtnLoading(true);
      const newRegisterData = R.clone(registerData);
      newRegisterData.password = CryptoJS.AES.encrypt(registerData.password, process.env.REACT_APP_SECRETKEY).toString();
      newRegisterData.confPassword = CryptoJS.AES.encrypt(registerData.confPassword, process.env.REACT_APP_SECRETKEY).toString();

      await axios.post(`${HOST}/register`, newRegisterData);

      setBtnLoading(false);
      // @TODO: add success message then redirect to login
      navigate('/register-success');
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.message);
      }
      setBtnLoading(false);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRegisterData({ ...registerData, [name]: value });
  };

  const imageHandler = async (e, type) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);

    const config = {
      headers: {
        Accept: 'multipart/form-data'
      }
    };

    try {
      // use axios for register
      const response = await axios.post(`${HOST}/upload/${type}`, formData, config)
      const filename = response.data
      
      if (type === 'users') {
        const filePath = `${FILES}/users/${filename}`;
        setIdentityImage(filePath);

        const newRegisterData = registerData;
        newRegisterData.identityImage = filename;
        setRegisterData(newRegisterData)
      }

      if (type === 'addresses') {
        const filePath = `${FILES}/addresses/${filename}`;
        setAddressImage(filePath)

        const newRegisterData = registerData;
        newRegisterData.addressImage = filename;
        setRegisterData(newRegisterData)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <section className='section'>
      <Container>
        <Row className='mt-4'>
          <Col md={{ span: 8, offset: 2 }}>
            <Form onSubmit={handleSubmit}>
              {msg? (
                <Alert variant='danger'>
                  {msg}    
                </Alert>
              ) : ( <></> )}

              <FloatingLabel
                label='Email'
                className='mb-3'
              >
                <Form.Control
                  type='email'
                  name='email'
                  value={registerData.email}
                  onChange={handleInputChange}
                  placeholder='Email'
                />
              </FloatingLabel>

              <FloatingLabel
                label='Password'
                className='mb-3'
              >
                <Form.Control
                  type='password'
                  name='password'
                  value={registerData.password}
                  onChange={handleInputChange}
                  placeholder='Password'
                />
              </FloatingLabel>

              <FloatingLabel
                label='Ulangi Password'
                className='mb-3'
              >
                <Form.Control
                  type='password'
                  name='confPassword'
                  value={registerData.confPassword}
                  onChange={handleInputChange}
                  placeholder='Ulangi Password'
                />
              </FloatingLabel>

              <FloatingLabel
                label='Nama'
                className='mb-3'
              >
                <Form.Control
                  type='text'
                  name='name'
                  value={registerData.name}
                  onChange={handleInputChange}
                  placeholder='Nama'
                />
              </FloatingLabel>

              <FloatingLabel
                label='Telepon'
                className='mb-3'
              >
                <Form.Control
                  type='text'
                  name='telephone'
                  value={registerData.telephone}
                  onChange={handleInputChange}
                  placeholder='Telepon'
                />
              </FloatingLabel>

              <FloatingLabel
                label='Alamat'
                className='mb-3'
              >
                <Form.Control
                  type='text'
                  name='address'
                  value={registerData.address}
                  onChange={handleInputChange}
                  placeholder='Alamat'
                />
              </FloatingLabel>

              <FloatingLabel
                label='Foto ARC'
                className='mb-3'
              >
                <Form.Control
                  type='file'
                  accept='image/*'
                  multiple={false}
                  onChange={(e) => { imageHandler(e, 'users') }}
                />

                {identityImage && (
                  <img alt='foto-arc' src={identityImage} className='img-profile ms-3 mt-2' />
                )}
              </FloatingLabel>

              <FloatingLabel
                label='Foto Alamat'
                className='mb-3'
              >
                <Form.Control
                  type='file'
                  accept='image/*'
                  multiple={false}
                  onChange={(e) => { imageHandler(e, 'addresses') }}
                />
                <p className='mb-0 text-tiny ms-2'>
                  *Gunakan foto alamat apabila Anda kesulitan menulis alamat
                </p>
                {addressImage && (
                  <img alt='foto-alamat' src={addressImage} className='img-profile ms-3 mt-2' />
                )}
              </FloatingLabel>

              <div className='d-grid gap-2'>
                <SubmitButton
                  loading={btnLoading}
                  label='Daftar'
                  type='submit'
                />
              </div>

              <p className='mt-2 text-center'>
                <span>Sudah punya akun? </span>
                <Link to='/login'>Login</Link>
              </p>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default CustomersRegister;
