import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../index.css'

function Footer() {
  return (
    <footer className='footer text-center text-sm-start text-muted bg-dark mt-3'>
      <section>
        <Container className='text-center text-sm-start pt-4 pb-4'>
          <Row className='mt-3'>
            <Col md='3' sm='12' className='mb-2'>
              <a href='/' target='_blank' className='text-white h2'>
                MAIMAI SHOP
              </a>
              <p className='mt-1 text-white'>
                © 2023
              </p>
            </Col>

            <Col md='3' xs='4'>
              <h6 className='text-uppercase text-white fw-bold mb-2'>
                Home
              </h6>
              <ul className='list-unstyled mb-4'>
                <li><a className='text-white-50' href='/about'>Tentang Kami</a></li>
                <li><a className='text-white-50' href='/shop'>Toko Kami</a></li>
              </ul>
            </Col>

            <Col md='3' xs='4'>
              <h6 className='text-uppercase text-white fw-bold mb-2'>
                Informasi
              </h6>
              <ul className='list-unstyled mb-4'>
                <li><a className='text-white-50' href='/shipping'>Info Pengiriman</a></li>
              </ul>
            </Col>

            <Col md='3' xs='4'>
              <h6 className='text-uppercase text-white fw-bold mb-2'>
                Support
              </h6>
              <ul className='list-unstyled mb-4'>
                <li><a className='text-white-50' href='/contact'>Layanan Pelanggan</a></li>
                <li><a className='text-white-50' href='https://www.instagram.com/maimaishop253/'>@maimaishop253</a></li>
                <li><span className='text-white-50'>0909934399</span></li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </footer>
  );
}

export default Footer;
