import React from 'react'
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SubmitButton = ({
  loading = false,
  label = '',
  icon = '',
  variant = 'primary',
  type = '',
  size = 'md',
  onClick,
  className
}) => {
  return (
    <Button
      type={type}
      size={size}
      variant={variant}
      disabled={ loading ? true : false }
      onClick={onClick}
      className={className}
    >
      {loading && (
        <>
          <Spinner
            as='span'
            animation='border'
            size='sm'
            role='status'
            aria-hidden='true'
            className='me-1'
          />
          <span className='visually-hidden'>Loading...</span>
        </>
      )}
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className={label ? 'me-1' : ''}
        />
      )}
      {label}
    </Button>
  )
}

export default SubmitButton;
