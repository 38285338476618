import axios from 'axios';
import { createContext, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import jwtInterceptor from './jwtInterceptor';
import * as R from 'ramda';

const AuthContext = createContext();
const HOST = process.env.REACT_APP_API_URL;

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    if (localStorage.getItem('accessToken')) {
      const accessToken = localStorage.getItem('accessToken');

      return jwt_decode(accessToken);
    }

    return null;
  });
 
  const navigate = useNavigate();
  const login = async (payload) => {
    const response = await axios.post(`${HOST}/login`, payload);
    const { accessToken, refreshToken } = response.data;

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);

    setUser(jwt_decode(accessToken));
    navigate('/');
  };

  const logout = async () => {
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      
      if (refreshToken) {
        await jwtInterceptor.post(`${HOST}/logout`, {
          refreshToken
        });
      }

      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      setUser(null);

      navigate('/');
    } catch (error) {
      console.log(error);
    }
  }

  const [cartItems, setCartItems] = useState(() => {
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];

    return cartItems
  });

  const addToCart = async (product) => {
    let cartItems = JSON.parse(localStorage.getItem('cart')) || [];

    const cartItem = R.pipe(
      R.clone,
      R.pick(['id', 'name', 'price', 'priceDiscount', 'customProduct'])
    )(product)

    if (Number(product?.customProduct) === 1) {
      cartItem.description = product?.description || ''
    }

    cartItem.qty = 1

    cartItems = R.pipe(
      R.append(cartItem),
      R.groupBy(R.prop('id')),
      R.mapObjIndexed((group, id) => ({
        id,
        name: R.pathOr('', ['0', 'name'])(group),
        price: R.pathOr(0, ['0', 'price'])(group),
        priceDiscount: R.pathOr(null, ['0', 'priceDiscount'])(group),
        customProduct: R.pathOr(0, ['0', 'customProduct'])(group),
        description: R.pathOr('', ['0', 'description'])(group),
        qty: R.sum(R.pluck('qty', group)),
      })),
      R.values
    )(cartItems)

    localStorage.setItem('cart', JSON.stringify(cartItems))

    setCartItems(cartItems)
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login,
        logout,
        addToCart,
        cartItems,
        setCartItems
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
 
export default AuthContext;
