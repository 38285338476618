import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../index.css'
import '../index.scss'

function Shipping() {
  return (
    <section className='section'>
      <Container>
        <Row>
          <Col md={12} className='text-center'>
            <h2>Pengiriman</h2>

            <hr />

            <p className='mb-2'>Kami berpusat di Fengjia. Biaya Pengiriman akan dihitung</p>
            <h3 className='mb-2'>NTD 5 per km</h3>
            <p className='mt-0'>dari Fengjia ke lokasi Anda.</p>

            <p>Biaya pengiriman akan dikonfirmasikan terlebih dahulu kepada Anda ketika kurir kami menerima pesanan. Ketika Anda setuju dengan biaya pengiriman yang ditentukan, kurir kami akan segera memproses order Anda.</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Shipping;
