import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Table, Modal, Badge, InputGroup, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faEye, faMagnifyingGlass, faCheck } from '@fortawesome/free-solid-svg-icons';
import AdminUserEditor from './UserEditor';
import jwtInterceptor from '../shared/jwtInterceptor';
import SubmitButton from '../utilities/SubmitButton';

function AdminUsers () {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [isNewUser, setIsNewUser] = useState();
  const [isEdit, setIsEdit] = useState(true);
  const [showUserEditor, setShowUserEditor] = useState(false);
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filter, setFilter] = useState('');
  const [totals, setTotals] = useState(0);
  const [msg, setMsg] = useState('');
  const [editorMsg, setEditorMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [init, setInit] = useState(true);

  // fetch users
  useEffect(() => {
    if (filter !== '' || !init) {
      fetchPaginatedUsers();
    }
  }, [currentPage, filter]);

  const fetchPaginatedUsers = async () => {
    try {
      if (init) {
        setInit(false);
      }

      setLoading(true);
      const response = await jwtInterceptor.get(`/admin/users?page=${currentPage}&search=${filter}`);

      setUsers(response.data.users);
      setTotalPages(response.data.totalPages);
      setTotals(response.data.totals);
      setLoading(false);
    } catch (error) {
      const errorMessage = error?.response?.data?.message || '';
      setMsg(errorMessage);
      setLoading(false);
    }
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  }

  const handleSearchInputChange = (e) => {
    const { name, value } = e.target;
    setFilter(value);
    setMsg('');
  }

  useEffect(() => {
    let timer = setTimeout(() => {
      fetchPaginatedUsers();
    }, 1000);

    return () => clearTimeout(timer);
  }, [filter]);

  // show/hide modal
  const handleCloseUserEditor = () => setShowUserEditor(false)
  const handleShowUserEditor = ({ isNewUser = true, isView = false, userId, selectedUser }) => {
    setIsNewUser(isNewUser);
    setEditorMsg('');

    if (userId) {
      setUser(selectedUser)
    } else {
      setUser({
        name: '',
        email: '',
        telephone: '',
        address: '',
        addressImage: '',
        identityImage: '',
        status: 'active'
      })
    }

    setIsEdit(!isView);
    setShowUserEditor(true)
  }

  // user data
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setBtnLoading(true);
      if (isNewUser) {
        await jwtInterceptor.post('/admin/users', user);
      } else {
        await jwtInterceptor.put(`/admin/users/${user.id}`, user);
      }

      setBtnLoading(false);
      fetchPaginatedUsers();
      handleCloseUserEditor();
    } catch (error) {
      const errorMessage = error?.response?.data?.message || '';
      setEditorMsg(errorMessage);
      setBtnLoading(false);
    }
  };

  const handleVerify = async (e, userId) => {
    e.preventDefault();

    if (userId) {
      try {
        setBtnLoading(true);
        await jwtInterceptor.put(`/admin/users/verify/${userId}`);
  
        setBtnLoading(false);
        fetchPaginatedUsers();
      } catch (error) {
        console.error('Error verifying user: ' + error.message);
        setBtnLoading(false);
      }
    }
  }

  return (
    <section className='section'>
      <Container>
        <Row>
          <Col xs={8}>
            <Button
              size='sm'
              onClick={() => handleShowUserEditor({
                isNewUser: true,
                userId: '',
                selectedUser: ''
              })}
            >
              Tambah Pelanggan Baru
            </Button>
          </Col>
          <Col xs={4} className='ms-auto'>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                />
              </InputGroup.Text>

              <Form.Control
                type='text'
                name='filter'
                value={filter}
                onChange={(e) => { handleSearchInputChange(e) }}
                placeholder='Cari Pelanggan...'
                size='sm'
              />
            </InputGroup>
          </Col>
        </Row>

        <hr />

        {loading && (
          <Row className='justify-content-center'>
            <span className='mt-3 loader'></span>
          </Row>
        )}

        {msg && (
          <Row>
            <Col xs={12}>
              <Alert variant='danger'>
                {msg}    
              </Alert>
            </Col>
          </Row>
        )}

        {!loading && (
          <>
            <Table responsive>
              <thead>
                <tr>
                  <th className='text-center'>Nama</th>
                  <th className='text-center'>Email</th>
                  <th className='text-center'>Telepon</th>
                  <th className='text-center'>Status</th>
                  <th className=''></th>
                </tr>
                {users.map((user) => (
                  <tr key={user.id} className='align-top'>
                    <td className='text-center'>{user.name}</td>
                    <td className='text-center'>{user.email}</td>
                    <td className='text-center'>{user.telephone}</td>
                    <td className='text-center'>
                      {
                        user.status === 'active' ? (
                          <Badge bg='success'>aktif</Badge>
                        ) : (
                          <Badge bg='danger'>inaktif</Badge>
                        )
                      }
                    </td>
                    <td className='text-center'>
                      <Button
                        size='sm'
                        variant='secondary'
                        className='btn-action'
                        onClick={() => handleShowUserEditor({
                          isNewUser: false,
                          isView: true,
                          userId: user.id,
                          selectedUser: user
                        })}
                      >
                        <FontAwesomeIcon
                          icon={faEye}
                        />
                      </Button>

                      <Button
                        size='sm'
                        className='btn-action'
                        onClick={() => handleShowUserEditor({
                          isNewUser: false,
                          userId: user.id,
                          selectedUser: user
                        })}
                      >
                        <FontAwesomeIcon
                          icon={faPen}
                        />
                      </Button>

                      {Number(user.isVerified) === 0 && (
                        <SubmitButton
                          loading={btnLoading}
                          className='btn-action'
                          size='sm'
                          variant='success'
                          onClick={(e) => handleVerify(e, user.id)}
                          icon={faCheck}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </thead>
            </Table>

            <nav className='d-flex justify-content-center mt-3'>
              <ul className='pagination'>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li className={(index + 1) === currentPage ? 'page-item active' : 'page-item'} key={index + 1}>
                    <button className='page-link' onClick={() => handlePageChange(index + 1)}>
                      {index + 1}  
                    </button>
                  </li>
                ))}
              </ul>
            </nav>

            <hr />
          </>
        )}
      </Container>

      <Modal size='lg' show={showUserEditor} onHide={handleCloseUserEditor}>
        <Modal.Header closeButton>
          <Modal.Title>
            {user.name ? user.name : 'Produk Baru'}
          </Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            {editorMsg && (
              <Alert variant='danger'>
                {editorMsg}    
              </Alert>
            )}

            <AdminUserEditor
              user={user}
              isEdit={isEdit}
              onchange={(e) => { handleInputChange(e) }}
            />
          </Modal.Body>

          {isEdit && (
            <Modal.Footer>
              <Button className='me-auto' size='sm' variant='secondary' onClick={handleCloseUserEditor}>
                Batal
              </Button>

              <SubmitButton
                loading={btnLoading}
                label='Simpan'
                type='submit'
                size='sm'
                variant='primary'
              />
            </Modal.Footer>
          )}
        </Form>
      </Modal>
    </section>
  );
}

export default AdminUsers;
