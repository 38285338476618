import { Button, NavDropdown, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import '../../index.css';

const CartBtn = ({ cartItems }) => {
  return (
    <NavDropdown
      title={(
        <>
          <FontAwesomeIcon
            icon={faShoppingCart}
          />

          {cartItems.length > 0 && (
            <Badge
              size='sm'
              bg='danger'
              className='ms-1'
            >
              {cartItems.length}
            </Badge>
          )}
        </>
      )}
      align='end'
      size='sm'
    >
      <div className='carts'>
        {cartItems.map((cartItem) => (
          <NavDropdown.Item key={cartItem.id} className='text-tiny'>
            <span className='items pb-1 ps-2 pe-2'>{cartItem.qty}x</span>
            <span className='items pb-1 ps-2 pe-2'>{cartItem.name.substring(0, 7)}</span>
            <span className='items pb-1 ps-2 pe-2'>${cartItem.priceDiscount ? cartItem.priceDiscount.toLocaleString() : cartItem.price.toLocaleString()}</span>
          </NavDropdown.Item>
        ))}
      </div>

      {cartItems.length > 0 && (
        <>
          <NavDropdown.Divider />
          <NavDropdown.Item
            href='/checkout'
            className='text-center d-grid gap-2'
          >
            <Button
              size='sm'
            >
              Checkout
            </Button>
          </NavDropdown.Item>
        </>
      )}
    </NavDropdown>
  )
}

export default CartBtn;
