import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../index.css'
import axios from 'axios';
import OrderItems from './orders/OrderItems';

const HOST = process.env.REACT_APP_API_URL;

const OrderSuccess = () => {
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    const getOrder = async () => {
      const orderNumber = location.state.orderNumber
      if (orderNumber) {
        try {
          setLoading(true);
          const response = await axios.get(`${HOST}/order/${orderNumber}`);
  
          setOrder(response.data)
          setLoading(false);
        } catch (error) {
          console.log(error)
          setLoading(false);
        }
      }
    }

    getOrder();
  }, [order.id])

  return (
    <section className='section'>
      <Container>
        {loading && (
          <Row className='justify-content-center'>
            <span className='mt-3 loader'></span>
          </Row>
        )}

        {!loading && (
          <>
            <Row>
              <Col lg={12}>
                <h3 className='text-center'>Selamat Pesanan Anda berhasil dibuat!!</h3>
                <h3 className='text-center mb-3'>#{order.orderNumber}</h3>

                <p className='text-center'>
                  Pesanan Anda akan segera kami proses dan Kurir kami akan segera menghubungi Anda untuk konfiirmasi pesanan dan ongkos kirim.
                </p>

                <hr />

                <OrderItems
                  order={order}
                />

                <div className='text-center'>
                  <p className='mb-0'>Untuk pertanyaan silahkan Hubungi kami</p>
                  <p className='fw-bold'>000000000</p>

                  <Button
                    size='sm'
                    className='me-1'
                    href='/shop'
                  >
                    Kembali Ke Toko
                  </Button>

                  <Button
                    size='sm'
                    href='/orders'
                  >
                    Cek Pesanan
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </section>
  )
} 

export default OrderSuccess;
