import axios from 'axios';

const HOST = process.env.REACT_APP_API_URL;
const jwtInterceptor = axios.create({
  baseURL: HOST,
  withCredentials: true
});
 
jwtInterceptor.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
});

jwtInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (error.response.status === 401 && refreshToken) {
      const payload = {
        accessToken,
        refreshToken
      };
 
      let response = await jwtInterceptor.post('/token', payload);
      const newAccessToken = response.data.accessToken
      localStorage.setItem('accessToken', newAccessToken);

      error.config.headers['Authorization'] = `bearer ${newAccessToken}`;
      return axios(error.config);
    } else {
      return Promise.reject(error);
    }
  }
);

export default jwtInterceptor;
