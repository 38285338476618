import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Alert, Table, Button } from 'react-bootstrap';
import jwtInterceptor from '../shared/jwtInterceptor';
import '../../index.css'
import UserOrder from './Order';

function Orders () {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totals, setTotals] = useState(0);
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(true);
 
  useEffect(() => {
    const fetchPaginatedOrders = async () => {
      try {
        setLoading(true);
        const response = await jwtInterceptor.get(`/orders?page=${currentPage}`);
  
        setOrders(response.data.orders);
        setTotalPages(response.data.totalPages);
        setTotals(response.data.totals);
        setLoading(false);
      } catch (error) {
        const errorMessage = error?.response?.data?.message || '';
        setMsg(errorMessage);
        setLoading(false);
      }
    }

    fetchPaginatedOrders();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  return (
    <section className='section'>
      <Container>
        {loading && (
          <Row className='justify-content-center'>
            <span className='mt-3 loader'></span>
          </Row>
        )}

        {!loading && (
          <>
            <Row>
              <Col xs={12}>
                <h3 className='text-center mb-0'>
                  Pesanan Anda
                </h3>
                <p className='text-center fw-bold'>
                  ({totals} Pesanan)
                </p>

                {msg && (
                  <Alert variant='danger'>
                    {msg}    
                  </Alert>
                )}

                {totals === 0 ? (
                  <div className='text-center'>
                    <p className='mb-1'>
                      Tidak Ada Pesanan
                    </p>

                    <Button
                      href='/shop'
                      size='sm'
                    >
                      Pesan Sekarang
                    </Button>
                  </div>
                ) : (
                  <>
                    <Table responsive className='mt-3'>
                      <thead>
                        <tr>
                          <th className='text-center'>Order</th>
                          <th>Total</th>
                          <th className='text-center'>Kurir</th>
                          <th className='text-center'>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders && orders.map((order, rowIndex) => (
                          <UserOrder
                            key={order.id}
                            order={order}
                            rowIndex={rowIndex}
                          />
                        ))}
                      </tbody>
                    </Table>

                    <p className='text-small text-center mt-3'>
                      Untuk membatalkan pesanan, silahkan hubungi Kami di nomor 000000 dan berikan nomor order yang ingin dibatalkan
                    </p>
                  </>
                )}
              </Col>
            </Row>

            <nav className='d-flex justify-content-center mt-3'>
              <ul className='pagination'>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li className={(index + 1) === currentPage ? 'page-item active' : 'page-item'} key={index + 1}>
                    <button className='page-link' onClick={() => handlePageChange(index + 1)}>
                      {index + 1}  
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </>
        )}
      </Container>
    </section>
  );
}

export default Orders;
