import React, { useState } from 'react';
import { Table, Button, Badge, Collapse, Modal } from 'react-bootstrap';
import '../../index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faList } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import OrderItems from '../orders/OrderItems';
import * as R from 'ramda';

const HOST = process.env.REACT_APP_API_URL;
const FILES = process.env.REACT_APP_FILES_API_URL;

function UserOrder ({ order, rowIndex }) {
  const [openItems, setOpenItems] = useState(false);

  const [showOrderModal, setShowOrderModal] = useState(false);
  const handleCloseOrderModal = () => setShowOrderModal(false);
  const handleShowOrderModal = () => setShowOrderModal(true);

  const orderStatuses = R.pipe(
    R.propOr([], 'orderStatuses'),
    R.sortWith([
      R.descend(R.prop('createdAt')),
    ])
  )(order)

  const lastStatus = R.head(orderStatuses)

  return (
    <>
      <tr key={order.id} className={rowIndex%2 === 0 ? 'table-secondary' : ''}>
        <td className='text-center'>{order.orderNumber}</td>
        <td>
          <span className='fw-bold'>${order.total.toLocaleString()}</span>
          {Number(order?.freeShipping) === 1 ? (
            <p className='text-tiny text-success'>Gratis Ongkir</p>
          ) : (
            <>
              <p className='text-tiny mb-0'>Order: ${order.preTotal.toLocaleString()}</p>
              {Number(order?.shippingFee) === 0 ? (
                <p className='text-tiny text-danger mb-0'>Ongkir: Belum Ditentukan</p>
              ): (
                <p className='text-tiny mb-0'>Ongkir: ${order.shippingFee.toLocaleString()}</p>
              )}
            </>
          )}
        </td>
        <td className='text-center'>
          {order.deliveryPersonName}
          <p className='text-tiny mb-0'>
            {order.deliveryPersonTelephone}
          </p>
        </td>
        <td className='text-center'>
          {lastStatus?.status === 'created' && (
            <Badge bg='primary'>Order Dibuat</Badge>
          )}

          {lastStatus?.status === 'processed' && (
            <Badge bg='warning'>Order Diproses</Badge>
          )}

          {lastStatus?.status === 'canceled' && (
            <Badge bg='danger'>Order Dibatalkan</Badge>
          )}

          {lastStatus?.status === 'done' && (
            <Badge bg='success'>Order Selesai</Badge>
          )}
          
          <p className='text-tiny mb-0'>
            {moment(lastStatus?.createdAt).format('YYYY-MM-DD hh:mm')}
          </p>
        </td>
        <td className='text-center'>
          <Button
            size='sm'
            variant='secondary'
            onClick={handleShowOrderModal}
          >
            <FontAwesomeIcon
              icon={faEye}
            />
          </Button>

          <Button
            size='sm'
            variant='secondary'
            aria-controls={`collapse-items-${order.id}`}
            aria-expanded={openItems}
            onClick={() => setOpenItems(!openItems)}
            className='ms-1'
          >
            <FontAwesomeIcon
              icon={faList}
            />
          </Button>
        </td>
      </tr>
      <Collapse in={openItems} id={`collapse-items-${order.id}`}>
        <tr className={rowIndex%2 === 0 ? 'table-secondary mb-1 text-tiny' : 'mb-1 text-tiny'}>
          <td></td>
          <td colSpan='99'>
            <div className='order-items'>
              <div className='d-block'>
                <div className='items fw-bold'>Produk</div>
                <div className='items fw-bold text-center'>Qty</div>
                <div className='items fw-bold text-end'>Harga</div>
                <div className='items fw-bold text-end'>Jumlah</div>
                <div className='items'></div>
              </div>
              {order?.orderItems && order.orderItems.map((orderItem) => (
                <div className='d-block' key={orderItem.id}>
                  <div className='items'>{orderItem.name}</div>
                  <div className='items text-center'>{orderItem.qty}</div>
                  <div className='items text-end'>${orderItem?.priceFinal?.toLocaleString()}</div>
                  <div className='items text-end'>${orderItem?.amount?.toLocaleString()}</div>
                  {(Number(orderItem?.customProduct) === 1 && Number(orderItem?.priceFinal) === 0) ? (
                    <div className='items text-danger'>*Harga Sementara</div>
                  ) : (
                    <div className='items'></div>
                  )}
                </div>
              ))}
            </div>
          </td>
        </tr>
      </Collapse>

      <Modal size='xl' show={showOrderModal} onHide={handleCloseOrderModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {order.orderNumber}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Table className='table-borderless'>
            <tbody>
              <tr>
                <td className='fw-bold p-1 w-25'>Nama</td>
                <td className='p-1'>{order.name} ({order.email})</td>
              </tr>
              <tr>
                <td className='fw-bold p-1'>Telepon</td>
                <td className='p-1'>{order.telephone}</td>
              </tr>
              <tr>
                <td className='fw-bold p-1'>Address</td>
                <td className='p-1'>
                  {order.address}
                  {order?.addressImage && (
                    <img alt='foto-alamat' src={`${FILES}/addresses/${order.addressImage}`} className='w-100 mt-2' />
                  )}
                </td>
              </tr>
              <tr>
                <td className='fw-bold p-1'>Kurir</td>
                <td className='p-1'>
                  {order?.deliveryPersonName ? (
                    <>{order.deliveryPersonName} ({order.deliveryPersonTelephone})</>
                  ) : (
                    <>-</>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>

          <hr />

          <p className='m-1 fw-bold'>Status Pesanan</p>

          <Table className='table-borderless'>
            <tbody>
              {orderStatuses && orderStatuses.map((orderStatus) => (
                <tr key={orderStatus.id}>
                  <td className='p-1 w-25'>
                    {orderStatus.status === 'created' && (
                      'Order Dibuat'
                    )}

                    {orderStatus.status === 'processed' && (
                      'Order Diproses'
                    )}

                    {orderStatus.status === 'canceled' && (
                      'Order Dibatalkan'
                    )}

                    {orderStatus.status === 'done' && (
                      'Order Selesai'
                    )}
                  </td>
                  <td className='p-1'>
                    {moment(orderStatus.createdAt).format('YYYY-MM-DD hh:mm')}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <hr />

          <OrderItems
            order={order}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UserOrder;