import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../index.css';
import '../index.scss';
import Logo from '../logo.jpg';

function Home() {
  return (
    <section className='section'>
      <Container>
        <Row>
          <Col md={12} className='text-center'>
            <h2>Selamat Datang di MaiMaiShop!</h2>

            <hr />

            <img src={Logo} alt='MaiMaiShop' className='mb-3' />
            
            <p>Kami dengan bangga mempersembahkan <span className='fw-bold'>MaiMaiShop</span>, toko online yang memahami betapa beragamnya kebutuhan Anda. Dengan komitmen untuk memberikan layanan terbaik kepada Anda, kami telah menyediakan berbagai macam barang kebutuhan yang dapat memenuhi segala keinginan dan tuntutan Anda.</p>

            <p className='fw-bold mt-5'>Mengapa Memilih <span className='fw-bold'>MaiMaiShop?</span></p>

            <p className='mb-0'><span className='fw-bold text-danger'>Ragam Produk Terlengkap</span></p> 
            <p className='mt-0'>Di <span className='fw-bold'>MaiMaiShop</span>, kami menawarkan beragam produk mulai dari pakaian, aksesori, peralatan rumah tangga, makanan, hingga perlengkapan elektronik. Anda akan menemukan semua yang Anda butuhkan dalam satu tempat, sehingga Anda tidak perlu lagi meluangkan waktu untuk mencari-cari di tempat lain.</p>

            <p className='mb-0'><span className='fw-bold text-danger'>Kualitas Terbaik</span></p>
            <p className='mt-0'>Kami bekerja sama dengan produsen dan merek terkemuka untuk memastikan bahwa setiap produk yang kami tawarkan memiliki kualitas yang terjamin. Kepercayaan Anda adalah prioritas utama kami.</p>

            <p className='mb-0'><span className='fw-bold text-danger'>Pengalaman Belanja Online yang Mudah</span></p>
            <p className='mt-0'><span className='fw-bold'>MaiMaiShop</span> dirancang untuk memudahkan Anda berbelanja secara online. Dengan antarmuka yang sederhana dan intuitif, Anda dapat menelusuri produk, membandingkan harga, dan melakukan pembelian dengan cepat dan aman.</p>

            <p className='mb-0'><span className='fw-bold text-danger'>Pengiriman Cepat</span></p>
            <p className='mt-0'>Kami menyediakan pengiriman ke lokasi Anda, sehingga Anda tinggal menunggu barang sampai.</p>

            <p className='mb-0'><span className='fw-bold text-danger'>Harga Terjangkau</span></p>
            <p className='mt-0'>Kami berkomitmen untuk menyediakan produk dengan harga yang kompetitif, sehingga Anda bisa berbelanja tanpa khawatir melampaui anggaran Anda.</p>

            <p className='mb-0'><span className='fw-bold text-danger'>Layanan Pelanggan Terbaik</span></p>
            <p className='mt-0'>Tim kami siap membantu Anda dengan pertanyaan atau permintaan apa pun. Kami percaya bahwa kepuasan pelanggan adalah kunci kesuksesan kami.</p>

            <p className='fw-bold mt-5'>Jelajahi Koleksi Kami!</p>
            <p>Kami mengundang Anda untuk menjelajahi koleksi produk kami yang beragam. Mulai dari fashion yang stylish, peralatan rumah tangga yang inovatif, hingga makanan lezat, kami memiliki semua yang Anda butuhkan untuk menjalani kehidupan yang lebih baik.</p>
            <p>Selamat berbelanja di <span className='fw-bold'>MaiMaiShop</span>! Kami berharap dapat menjadi destinasi belanja online pilihan Anda. Terima kasih atas dukungan Anda, dan kami berharap dapat memenuhi semua kebutuhan Anda.</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Home;
