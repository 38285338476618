import React, { useEffect, useState } from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';

const HOST = process.env.REACT_APP_API_URL;
const FILES = process.env.REACT_APP_FILES_API_URL;

function AdminUserEditor ({ user, isEdit, isAdmin = false, onchange }) {
  const [identityImage, setIdentityImage] = useState('');
  const [addressImage, setAddressImage] = useState('');

  useEffect(() => {
    if (user?.addressImage) {
      const filePath = `${FILES}/addresses/${user.addressImage}`;
      setAddressImage(filePath)
    }

    if (user?.identityImage) {
      const filePath = `${FILES}/users/${user.identityImage}`;
      setIdentityImage(filePath);
    }
  }, [user])

  const handleChange = event => {
    onchange(event);
  }

  return (
    <>
      <FloatingLabel
        label='Nama'
        className='mb-3'
      >
        <Form.Control
          type='text'
          name='name'
          value={user.name}
          onChange={handleChange}
          disabled={!isEdit}
          placeholder='Nama'
        />
      </FloatingLabel>

      <FloatingLabel
        label='Email'
        className='mb-3'
      >
        <Form.Control
          type='text'
          name='email'
          value={user.email}
          onChange={handleChange}
          disabled={!isEdit}
          placeholder='Email'
        />
      </FloatingLabel>
        

      <FloatingLabel
        label='Telepon'
        className='mb-3'
      >
        <Form.Control
          type='text'
          name='telephone'
          value={user.telephone}
          onChange={handleChange}
          disabled={!isEdit}
          placeholder='Telepon'
        />
      </FloatingLabel>

      {!isAdmin && (
        <FloatingLabel
          label='Alamat'
          className='mb-3'
        >
          <Form.Control
            type='text'
            name='address'
            value={user.address}
            onChange={handleChange}
            disabled={!isEdit}
            placeholder='Alamat'
          />
        </FloatingLabel>
      )}

      <FloatingLabel
        label='Status'
        className='mb-3'
      >
        <Form.Select
          name='status'
          value={user.status}
          onChange={handleChange}
          disabled={!isEdit}
        >
          <option value='active'>Aktif</option>
          <option value='inactive'>Inaktif</option>
        </Form.Select>
      </FloatingLabel>

      {!isAdmin && (
        <>
          {!isEdit && identityImage && (
            <img alt='foto-arc' src={identityImage} className='img-profile mt-2' />
          )}

          {!isEdit && addressImage && (
            <img alt='foto-arc' src={addressImage} className='img-profile mt-2' />
          )}
        </>
      )}
    </>
  );
}

export default AdminUserEditor;
