import React, { useContext, useState } from 'react';
import { Card, Button, Col, Row, Modal, Form, FloatingLabel } from 'react-bootstrap';
import AuthContext from './shared/AuthContext';
import '../index.css';
import * as R from 'ramda';

const HOST = process.env.REACT_APP_API_URL;
const FILES = process.env.REACT_APP_FILES_API_URL;

function ShopProduct({ product, isCustomProduct = false }) {
  const { addToCart, cartItems } = useContext(AuthContext);
  const [showCustomProduct, setShowCustomProduct] = useState(false);
  const [customProduct, setCustomProduct] = useState({
    name: '',
    description: ''
  });

  const handleCloseCustomProduct = () => setShowCustomProduct(false);
  const handleShowCustomProduct = () => setShowCustomProduct(true);

  const productId = R.toString(product?.id);
  const qty = R.pipe(
    R.map(cartItem => {
      if (cartItem.id === productId) {
        return cartItem
      }
  
      return {}
    }),
    R.reject(R.isEmpty),
    R.head,
    R.propOr('', 'qty')
  )(cartItems);

  const handleCustomProductInputChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    setCustomProduct({ ...customProduct, [name]: value });
  }

  const addCustomProductToCart = () => {
    const newCustomProduct = customProduct;
    newCustomProduct.id = Math.floor(100000 + Math.random() * 900000)
    newCustomProduct.price = 0;
    newCustomProduct.priceFinal = 0;
    newCustomProduct.qty = 1;
    newCustomProduct.customProduct = 1;

    addToCart(newCustomProduct);
    handleCloseCustomProduct();
  }

  return (
    <>
      <Col md={12}>
        <Card className='shadow-0 border rounded-3'>
          <Card.Body>
            <Row className='g-4'>
              {
                isCustomProduct ? (
                  <Col md={12} className='text-center'>
                    <p className='mb-0 fw-bold'>
                      Tidak menemukan produk yang Anda cari?
                    </p>
                    <p className='mb-2'>
                      Klik tombol dibawah ini dan kurir kami akan mencarikan barang yang Anda perlukan.
                    </p>
                    <Button
                      size='sm'
                      onClick={handleShowCustomProduct}
                    >
                      Request Produk
                    </Button>
                  </Col>
                ) : (
                  <>
                    <Col sm={2} className='d-flex justify-content-center align-items-center'>
                      {product?.productImage && (
                        <img
                          alt={product.name}
                          src={`${FILES}/products/${product.productImage}`}
                          className='img-product'
                        />
                      )}
                    </Col>

                    <Col sm={7}>
                      <h5 className='text-center text-sm-start'>{product.name}</h5>
                      {/* <div className='d-flex flex-row justify-content-center justify-content-sm-start'>
                        <div className='text-warning mb-1 me-2'>
                          <FontAwesomeIcon icon={faStar} />
                          <FontAwesomeIcon icon={faStar} />
                          <FontAwesomeIcon icon={faStar} />
                          <FontAwesomeIcon icon={faStar} />
                          <FontAwesomeIcon icon={faStarHalfAlt} />
                          <span className='ms-1'>
                            4.5
                          </span>
                        </div>
                        <span className='text-muted'>154 orders</span>
                      </div> */}
                      <div className='mb-0' dangerouslySetInnerHTML={{__html: product.description}}></div>
                    </Col>

                    <Col sm={3} className='border-sm-start-none border-start mt-0 mt-sm-4'>
                      <div className='d-flex flex-row justify-content-center justify-content-sm-start align-items-center mb-1'>
                        {
                          product.priceDiscount ? (
                            <>
                              <h4 className='mb-1 me-1'>${product.priceDiscount.toLocaleString()}</h4>    
                              <span className='text-danger'><s>${product.price.toLocaleString()}</s></span>
                            </>
                          ) : (
                            <h4 className='mb-1 me-1'>${product.price.toLocaleString()}</h4>    
                          )
                        }
                      </div>
                      {/* <p className='text-danger text-tiny text-center text-sm-start'>
                        Belum termasuk biaya kirim
                      </p> */}
                      <div className='mt-2 d-grid gap-2'>
                        <Button
                          size='sm'
                          className='btn btn-primary shadow-0 me-1'
                          type='button'
                          onClick={() => { addToCart(product) }}
                        >
                          Beli
                        </Button>
                      </div>
                      
                      {qty && (
                        <p className='mb-0 text-center text-tiny'>Jumlah: {qty}</p>
                      )}
                    </Col>
                  </>
                )
              }
            </Row>
          </Card.Body>
        </Card>
      </Col>

      <Modal size='xl' show={showCustomProduct} onHide={handleCloseCustomProduct}>
        <Modal.Header closeButton>
          <Modal.Title>
            Request Produk
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FloatingLabel
            label='Nama Produk'
            className='mb-3'
          >
            <Form.Control
              type='text'
              name='name'
              value={customProduct.name}
              onChange={(e) => { handleCustomProductInputChange(e) }}
              placeholder='Nama Produk'
            />
          </FloatingLabel>

          <FloatingLabel
            label='Catatan'
            className='mb-3'
          >
            <Form.Control
              type='textarea'
              name='description'
              value={customProduct.description}
              onChange={(e) => { handleCustomProductInputChange(e) }}
              placeholder='description'
              style={{ height: '60px' }}
            />
            <p className='mb-0 text-tiny'>
              *Tambahkan informasi untuk mempermudah Kami mencari produk tersebut (ex: Nama Toko, Lokasi, Detail Produk)
            </p>
          </FloatingLabel>

          <div className='text-center mt-3'>
            <hr />
            <p>Setelah Anda melakukan checkout, Kami akan mencarikan dan menginformasikan kembali harga dan detail produk sesuai dengan apa yang Kami temukan.</p>
            <p>Apabila Anda sudah mengetahui detail barang dari Kami dan ingin melanjutkan pesanan, Kami akan memproses produk dan order.</p>
          </div>
        </Modal.Body>
        
        <Modal.Footer>
          <Button size='sm' className='me-auto' variant='secondary' onClick={handleCloseCustomProduct}>
            Batal
          </Button>

          <Button type='submit' size='sm' variant='primary' onClick={addCustomProductToCart}>
            Beli
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ShopProduct;
