import React from 'react';
import { Table } from 'react-bootstrap';
import '../../index.css'
import * as R from 'ramda';

function OrderItems ({ order }) {
  const hasCustomProducts = R.pipe(
    R.propOr([], 'orderItems'),
    R.map(orderItem => {
      const customProduct = Number(orderItem?.customProduct) || 0
      const priceFinal = Number(orderItem?.priceFinal) || 0

      if (customProduct === 1 && priceFinal === 0) {
        return true
      }
      
      return false
    }),
    R.includes(true)
  )(order)

  const freeShipping = Number(order?.freeShipping) || 0
  const shippingFee = Number(order?.shippingFee) || 0
  const hasCalculatedShippingFee = (
    (freeShipping === 1) ||
    (freeShipping === 0 && shippingFee > 0)
  )

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Produk</th>
            <th className='text-center'>Qty</th>
            <th className='text-end'>Harga</th>
            <th className='text-end'>Jumlah</th>
          </tr>
        </thead>
        <tbody>
          {order?.orderItems && order.orderItems.map((orderItem) => (
            <tr key={orderItem.id} className='align-middle'>
              <td>
                {orderItem.name}
                {(Number(orderItem?.customProduct) === 1 && Number(orderItem?.priceFinal) === 0) && (
                  <p className='text-tiny text-danger mb-0'>*Harga Sementara</p>
                )}
              </td>
              <td className='text-center'>
                {orderItem.qty}
              </td>
              <td className='text-end'>
                ${orderItem?.priceFinal?.toLocaleString()}
              </td>
              <td className='text-end'>
                ${orderItem?.amount?.toLocaleString()}
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan='3' className='pb-1 border-bottom-0 text-end fw-bold'>Subtotal</td>
            <td className='pb-1 border-bottom-0 text-end'>${order?.preTotal?.toLocaleString()}</td>
          </tr>
          <tr>
            <td colSpan='3' className='py-1 border-bottom-0 text-end fw-bold'>Ongkir</td>
            <td className='py-1 border-bottom-0 text-end'>
              {Number(order?.freeShipping) === 1 ? (
                '0'
              ) : (
                Number(order?.shippingFee) === 0 ? (
                  <span className='text-danger'>Belum Ditentukan</span>
                ) : (
                  <>${order?.shippingFee?.toLocaleString()}</>
                )
              )}
            </td>
          </tr>
          <tr>
            <td colSpan='3' className='py-1 border-bottom-0 text-end fw-bold'>Total</td>
            <td className='py-1 border-bottom-0 text-end fw-bold'>${order?.total?.toLocaleString()}</td>
          </tr>
        </tbody>
      </Table>

      <div className='text-center'>
        {hasCalculatedShippingFee && hasCustomProducts && (
          <p className='text-tiny text-danger'>
            Total belum termasuk biaya barang yang Anda request. Harga akan diupdate ketika Kami telah menemukan barang tersebut. Kami juga akan mengkonfirmasikan kepada Anda lagi.
          </p>
        )}

        {!hasCalculatedShippingFee && hasCustomProducts && (
          <p className='text-tiny text-danger'>
            Total belum termasuk biaya kirim dan biaya barang yang Anda request. Harga akan diupdate ketika Kami telah menemukan barang tersebut. Kami juga akan mengkonfirmasikan kepada Anda lagi.
          </p>
        )}

        {!hasCalculatedShippingFee && !hasCustomProducts && (
          <p className='text-tiny text-danger'>
            Total belum termasuk biaya kirim. Harga akan diupdate ketika Kami telah menemukan barang tersebut. Kami juga akan mengkonfirmasikan kepada Anda lagi.
          </p>
        )}
      </div>
    </>
  )
}

export default OrderItems;
