import './App.css';
import { Routes, Route } from 'react-router-dom';
import { AuthContextProvider } from './components/shared/AuthContext';
import ProtectedRoute from './components/shared/ProtectedRoute';

import Layout from './components/shared/Layout';
import Home from './components/Home';
import Shipping from './components/Shipping';
import About from './components/About';
import Contact from './components/Contact';
import Shop from './components/Shop';
import Checkout from './components/Checkout';

import UsersLogin from './components/users/Login';
import UsersProfile from './components/users/Profile';
import UsersRegister from './components/users/Register';
import UsersRegisterSuccess from './components/users/RegisterSuccess';
import UsersPassword from './components/users/Password';

import OrderSuccess from './components/OrderSuccess';
import Orders from './components/users/Orders';

import AdminProducts from './components/admin/Products';
import AdminUsers from './components/admin/Users';
import AdminSuper from './components/admin/Super';
import AdminOrders from './components/admin/Orders';

function App() {
  return (
    <>
      <AuthContextProvider>
        <Layout>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/shipping' element={<Shipping />} />
            <Route
              path='/login'
              element={
                <ProtectedRoute accessBy='non-authenticated'>
                  <UsersLogin />
                </ProtectedRoute>
              }
            />
            <Route path='/shop' element={<Shop />} />
            <Route path='/order-success' element={<OrderSuccess />} />
            <Route path='/checkout' element={<Checkout />} />
            <Route
              path='/profile'
              element={
                <ProtectedRoute accessBy='authenticated'>
                  <UsersProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path='/change-password'
              element={
                <ProtectedRoute accessBy='authenticated'>
                  <UsersPassword />
                </ProtectedRoute>
              }
            />
            <Route
              path='/orders'
              element={
                <ProtectedRoute accessBy='authenticated'>
                  <Orders />
                </ProtectedRoute>
              }
            />
            <Route path='/register' element={<UsersRegister />} />
            <Route path='/register-success' element={<UsersRegisterSuccess />} />
            <Route
              path='/admin/products'
              element={
                <ProtectedRoute accessBy='authenticated' role='admin'>
                  <AdminProducts />
                </ProtectedRoute>
              }
            />
            <Route
              path='/admin/users'
              element={
                <ProtectedRoute accessBy='authenticated' role='admin'>
                  <AdminUsers />
                </ProtectedRoute>
              }
            />
            <Route
              path='/admin/super'
              element={
                <ProtectedRoute accessBy='authenticated' role='admin'>
                  <AdminSuper />
                </ProtectedRoute>
              }
            />
            <Route
              path='/admin/orders'
              element={
                <ProtectedRoute accessBy='authenticated' role='admin'>
                  <AdminOrders />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Layout>
      </AuthContextProvider>
    </>
  );
}

export default App;
