import React, { useEffect, useState, useContext } from 'react'
import AuthContext from '../shared/AuthContext';
import { Form, FloatingLabel } from 'react-bootstrap';
import axios from 'axios';
import * as R from 'ramda';
import '../../index.css';

const HOST = process.env.REACT_APP_API_URL;
const FILES = process.env.REACT_APP_FILES_API_URL;

const ProfileEditor = ({ userData, setUserData, handleInputChange, isEdit, isCheckout = false}) => {
  const { user } = useContext(AuthContext);

  const [identityImage, setIdentityImage] = useState('');
  const [addressImage, setAddressImage] = useState('');

  useEffect(() => {
    if (userData?.addressImage) {
      const filePath = `${FILES}/addresses/${userData.addressImage}`;
      setAddressImage(filePath)
    }

    if (userData?.identityImage) {
      const filePath = `${FILES}/users/${userData.identityImage}`;
      setIdentityImage(filePath);
    }
  }, [userData])

  const imageHandler = async (e, type) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    formData.append('type', 'addresses')

    const config = {
      headers: {
        Accept: 'multipart/form-data'
      }
    };

    try {
      const response = await axios.post(`${HOST}/upload/${type}`, formData, config);
      const filename = response.data;

      if (type === 'users') {
        const filePath = `${FILES}/users/${filename}`;
        setIdentityImage(filePath);

        const newUserData = userData;
        newUserData.identityImage = filename;
        setUserData(newUserData)
      }

      if (type === 'addresses') {
        const filePath = `${FILES}/addresses/${filename}`;
        setAddressImage(filePath)

        const newUserData = userData;
        newUserData.addressImage = filename;
        setUserData(newUserData)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <FloatingLabel
        label='Email'
        className='mb-3'
      >
        <Form.Control
          type='text'
          name='email'
          value={userData.email}
          onChange={handleInputChange}
          placeholder='Email'
          disabled={!R.isNil(user)}
        />

        {!isCheckout && (
          <p className='mb-0 ms-2 text-tiny'>
            *Untuk mengubah alamat email silahkan hubungi admin di nomor 000000000
          </p>
        )}
      </FloatingLabel>

      <FloatingLabel
        label='Nama'
        className='mb-3'
      >
        <Form.Control
          type='text'
          name='name'
          value={userData.name}
          onChange={handleInputChange}
          placeholder='Nama'
          disabled={!isEdit || (!R.isNil(user) && isCheckout)}
        />
      </FloatingLabel>

      <FloatingLabel
        label='Telepon'
        className='mb-3'
      >
        <Form.Control
          type='text'
          name='telephone'
          value={userData.telephone}
          onChange={handleInputChange}
          placeholder='Telepon'
          disabled={!isEdit || (!R.isNil(user) && isCheckout)}
        />
      </FloatingLabel>

      {(!isEdit || !R.isNil(user)) ? (
        identityImage ? (
          <div className='img-bordered mb-3'>
            <p className='mb-0 text-tiny label-img'>Foto ARC</p>
            <img alt='foto-arc' src={identityImage} className='img-profile' />
          </div>
        ) : (
          <FloatingLabel
            label='Foto ARC'
            className='mb-3'
          >
            <Form.Control
              type='file'
              accept='image/*'
              multiple={false}
              onChange={(e) => { imageHandler(e, 'users') }}
              disabled={!isEdit || !R.isNil(user)}
            />
          </FloatingLabel>
        )
      ) : (
        identityImage ? (
          <>
            <FloatingLabel
              label='Foto ARC'
            >
              <Form.Control
                type='file'
                accept='image/*'
                multiple={false}
                onChange={(e) => { imageHandler(e, 'users') }}
                disabled={!isEdit || !R.isNil(user)}
              />
            </FloatingLabel>

            <img alt='foto-arc' src={identityImage} className='img-profile ms-3 my-2' />
          </>
        ) : (
          <FloatingLabel
            label='Foto ARC'
            className='mb-3'
          >
            <Form.Control
              type='file'
              accept='image/*'
              multiple={false}
              onChange={(e) => { imageHandler(e, 'users') }}
              disabled={!isEdit || !R.isNil(user)}
            />
          </FloatingLabel>
        )
      )}

      <FloatingLabel
        label='Alamat'
        className='mb-3'
      >
        <Form.Control
          as='textarea'
          name='address'
          value={userData.address}
          onChange={handleInputChange}
          placeholder='Alamat'
          disabled={!isEdit}
          style={{ height: '60px' }}
        />
      </FloatingLabel>

      {isEdit ? (
        addressImage ? (
          <>
            <FloatingLabel
              label='Foto Alamat'
              className='mb-3'
            >
              <Form.Control
                type='file'
                accept='image/*'
                multiple={false}
                onChange={(e) => { imageHandler(e, 'addresses') }}
                disabled={!isEdit}
              />
              <p className='mb-0 text-tiny ms-2'>
                *Gunakan foto alamat apabila Anda kesulitan menulis alamat
              </p>

              <img alt='foto-alamat' src={addressImage} className='img-profile ms-3 mt-2' />
            </FloatingLabel>
          </>
        ) : (
          <>
            <FloatingLabel
              label='Foto Alamat'
              className='mb-3'
            >
              <Form.Control
                type='file'
                accept='image/*'
                multiple={false}
                onChange={(e) => { imageHandler(e, 'addresses') }}
                disabled={!isEdit}
              />
              <p className='mb-0 text-tiny ms-2'>
                *Gunakan foto alamat apabila Anda kesulitan menulis alamat
              </p>
            </FloatingLabel>
          </>
        )
      ) : (
        addressImage ? (
          <div className='img-bordered mb-3'>
            <p className='mb-0 text-tiny label-img'>Foto Alamat</p>
            <img alt='foto-alamat' src={addressImage} className='img-profile' />
          </div>
        ) : (
          <>
            <FloatingLabel
              label='Foto Alamat'
              className='mb-3'
            >
              <Form.Control
                type='file'
                accept='image/*'
                multiple={false}
                onChange={(e) => { imageHandler(e, 'addresses') }}
                disabled={!isEdit}
              />
              <p className='mb-0 text-tiny ms-2'>
                *Gunakan foto alamat apabila Anda kesulitan menulis alamat
              </p>
            </FloatingLabel>
          </>
        )
      )}
    </>
  )
}

export default ProfileEditor;
