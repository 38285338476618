import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import '../index.css';
import '../index.scss';

function Contact() {
  return (
    <section className='section'>
      <Container>
        <Row>
          <Col md={12} className='text-center'>
            <h2>Layanan Pelanggan</h2>

            <hr />

            <div className='text-center'>
              <p>Tetap terhubung dengan kami melalui berbagai platform:</p>

              <p className='mb-1'>
                <FontAwesomeIcon
                  icon={faInstagram}
                  className='me-1'
                />
                <a href='https://www.instagram.com/maimaishop253/'>@maimaishop253</a>
              </p>
              <p className='mb-1'>
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  className='me-1'
                />
                09029393877
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faGlobe}
                  className='me-1'
                />
                <a href='https://mai-mai.pw'>MaiMaiShop</a>
              </p>
              <p>Kami selalu siap untuk mendengar dari Anda. Jangan ragu untuk menghubungi kami jika Anda memiliki pertanyaan, komentar, atau masukan. Kami berterima kasih atas dukungan Anda!</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Contact;
